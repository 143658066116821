import React from 'react'
import SetHeaderContent from '../printElements/SetHeaderContent'
import QuestionContent from '../../../printKit/QuestionContent'


class GhostReportPack extends React.Component{

	constructor(props) {
		super(props)
		this.measureGhosts=this.measureGhosts.bind(this)
		this.state = {
			heights:{},
		}
	} 


	componentDidMount(){
		this.measureGhosts()
	}



	componentDidUpdate(prevProps) {
		if(
			(this.props.columnCount!== prevProps.columnCount)
			||(this.props.showImages!== prevProps.showImages)
			||(this.props.paperSize!== prevProps.paperSize)){
			setTimeout(function() { //Start the timer
				this.measureGhosts()
			}.bind(this),	1)
		}
	}



	measureGhosts(){
		const {polls}=this.props
		let heights={}

		polls.map((poll,index) => {
			if(poll.setObject){ 
				const headerEl=this[`setHeader${poll.id}`]
				if(headerEl){
					heights[poll.id]=headerEl.clientHeight
				}
				Object.keys(poll.filteredPolls).map((key,index)=>{
					const setPollPoll=poll.filteredPolls[key] 	
					const el =this[`ghost${poll.id}-${setPollPoll.id}`]
					if(el){
						heights[`${poll.id}-${setPollPoll.id}`]=el.clientHeight						
					}
					return null
				})
			}				
			else{
				const el =this[`ghost${poll.id}`]
				if(el){
					heights[poll.id]=el.clientHeight						
				}
			}
			return null
		})
		
		const spacerEl=this.spacer
		if(spacerEl){
			heights.spacer = spacerEl.clientHeight
		}

		this.setState({heights:heights})
		this.props.saveGhostHeights(heights)


	}







	render(){ 
		const {
			polls,
			showQuestionGrades,
			showStudentScores,
			showResponses,
			reportPackSettings,
			columnCount,
			showImages,
			paperSize
		}=this.props

		


		const {showResponseCapturedDate}=reportPackSettings


		const highlightCorrect = false
		const reportPackColumnCount=columnCount



		return(
			<React.Fragment>
				<div className={'printPack printPack--ghostSpacer ' + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ')}>
					<div className='printPack-page'>
						<div ref={c => {this.spacer = c}} className='printPack-page-spacer'/>
					</div>
				</div>
				<div className={'printPack printPack--ghost ' + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ') + (reportPackColumnCount === 2 ? 'printPack--twoColumn ' : '') + (reportPackColumnCount === 1 ? 'printPack--singleColumn ' : '')}>
					<div className='printPack-page'> 
						<div className='printPack-page-pageColumn'>
													
						{polls.map((poll,index) => {
					 		if(poll.setObject){ //is a setPoll
					 			const setCounter=index+1
					 		return(
						 		<React.Fragment key={poll.id}>	 
									<div className='printPack-header' ref={c => {this[`setHeader${poll.id}`] = c}}>
										<SetHeaderContent
											score={0}
											setCounter={0}
											showStudentScores={showStudentScores}
											setName={poll.snapshot.name}
										/>
									</div>


								{Object.keys(poll.filteredPolls).map((key,index)=>{
									const setPollPoll=poll.filteredPolls[key] 							
									return(
										<div key={`${poll.id}-${setPollPoll.id}`} ref={c => {this[`ghost${poll.id}-${setPollPoll.id}`] = c}} className={'printPackQuestion printPackQuestion--isGraded ' }>										
											<QuestionContent 
												showQuestionGrades={showQuestionGrades}
												showQuestionNumbers={true}
												showImages={showImages}
												showResponseCapturedDate={showResponseCapturedDate}
												highlightCorrect={highlightCorrect}
												key={setPollPoll.id}
												questionCounter={`${setCounter}.${index+1}`}
												question={setPollPoll.snapshot}
												showResponses={showResponses}
												isSurvey={false}
											/>
										</div>
										
									)
								})}

							</React.Fragment>

						)
					 	}
					 	else{
					 		// const response=poll[responseTable][responseIdentifier]
					 		return(
					 			<div key={poll.id} ref={c => {this[`ghost${poll.id}`] = c}}  className={'printPackQuestion printPackQuestion--isGraded ' }>		
						 		<QuestionContent
						 			showQuestionGrades={showQuestionGrades}
						 			showQuestionNumbers={true}
						 			showImages={showImages}
						 			highlightCorrect={highlightCorrect}
						 			key={poll.id}
									questionCounter={index+1}
									question={poll.snapshot}	
									showResponses={showResponses}	
									isSurvey={poll.correctPercent===-1}	
									showResponseCapturedDate={showResponseCapturedDate}
								/>
								</div>
						
							)	
					 	} 	
					 	})}				 	 	
					
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}


export default GhostReportPack
