const { compact } = require('lodash');

const categoryMap = {
  'necessary': 'C0001',
  'performance': 'C0002',
  'functional': 'C0003',
  'targeting': 'C0004',
  'socialmedia': 'C0005',
}

function revisitConsent() {
  if (window.OneTrust){
    window.OneTrust.ToggleInfoDisplay()
  }
}

function getActiveGroups() {
  if (window.OnetrustActiveGroups) {
    return compact(window.OnetrustActiveGroups.split(','))
  } else {
    return []
  }
}

function isCategoryConsented(category) {
  const categoryCode = categoryMap[category]
  return getActiveGroups().includes(categoryCode)
}

function getOneTrustCookieSettingsButtonText() {
  if (window.OneTrust) {
    const oneTrustDomainData = window.OneTrust.GetDomainData()
    return oneTrustDomainData.CookieSettingButtonText
  }
  return ''
}

function getCookieSettingsButtonText() {
  // For tests, remove before production:
  // return 'Do Not Sell Or Share My Personal Information'
  if (isUnderCcpa()){
    return 'Do Not Sell Or Share My Personal Information'
  } else {
    return 'Cookie Settings'
  }
}

function isUnderCcpa() {
  // For tests, remove before production:
  return true
  return getOneTrustCookieSettingsButtonText().includes('Do Not Sell');
}

function isContentAllowed(contentType) {
  // return false;
  switch (contentType) {
    case 'youtube':
      return isCategoryConsented('targeting');
    default:
      return false;
  }
}


export {
  isContentAllowed,
  revisitConsent,
  getCookieSettingsButtonText,
  isUnderCcpa,
}
