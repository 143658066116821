import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modals'
// import { hideModal } from '../../actions/modals'
// import { showNotification } from '../actions/notifications'
import Button from '../../components/uiKit/Button'

function CookiePolicyContainer(props) {
  const { hideModal, showNotification } = props

  // const [submitLoading, setSubmitLoading] = useState(false)
  const [cookiePolicyContent, setCookiePolicyContent] = useState('')

  useEffect(() => {
    const policyDiv = document.getElementById('ot-sdk-cookie-policy');

    if (policyDiv) {
      const policyContent = policyDiv.innerHTML
      setCookiePolicyContent(policyContent);
    }
  }, [])

  return (
    <div className="cookiePolicy-container">
      <div className='cookiePolicy-content'>
        <p> This text is generated by ChatGPT 4o as an example:</p>
        <p>At Plickers, we are committed to protecting the privacy and personal data of our users in compliance with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). This Cookie Policy outlines how we use cookies on our website, the types of cookies we use, and how users can manage their cookie preferences. By continuing to browse our site, you agree to our use of cookies, unless you have disabled them via your browser or settings.</p>

        <h2>1. What are Cookies?</h2>
        <p>Cookies are small text files that are stored on your device (computer, tablet, or smartphone) when you visit a website. These files allow websites to recognize your device, track your actions on the site, and store preferences to improve your browsing experience. Plickers uses both session cookies, which expire when you close your browser, and persistent cookies, which remain on your device until they expire or are deleted. These cookies help us enhance our services, analyze web traffic, and personalize content for our users.</p>

        <h2>2. Types of Cookies We Use</h2>
        <p>Plickers uses different types of cookies, each serving a specific purpose. We use essential cookies that are necessary for our website to function properly, including those that enable secure login and load-balancing to ensure our website runs smoothly. We also use analytical cookies to gather data on how our users interact with our site, helping us improve our services. Marketing cookies may be used to track user behavior across websites in order to deliver personalized advertisements based on your interests.</p>

        <h2>3. Your Rights Under GDPR and CCPA</h2>
        <p>As a user, GDPR grants you the right to access, modify, and delete any personal data collected via cookies, as well as the right to withdraw consent to the use of cookies at any time. Under CCPA, California residents have the right to opt-out of the sale of personal data collected through cookies. Plickers does not sell personal data to third parties, but we ensure that users can easily manage their cookie preferences through the settings provided on our website. We also offer clear options to opt-out of non-essential cookies, such as marketing cookies.</p>

        <h2>4. Managing Your Cookie Preferences</h2>
        <p>Users can manage their cookie preferences at any time by accessing the cookie settings on our website. You can also control cookies through your browser settings, where you can choose to block or delete cookies. However, please note that disabling certain cookies may affect the functionality of our website and limit your ability to use some features. Plickers provides clear instructions and a dedicated section on how to manage and withdraw your consent to cookies, in line with GDPR and CCPA regulations.</p>

        <h2>5. Updates to Our Cookie Policy</h2>
        <p>Plickers may update this Cookie Policy from time to time to reflect changes in legal requirements or the way we use cookies. We encourage you to review this policy periodically to stay informed about how we are protecting your personal data. If we make significant changes to this policy, we will notify users through our website or via email. Your continued use of our website after any updates will constitute acceptance of those changes.</p>

        <p> Following cookie list is loaded from the OneTrust:</p>
        <div dangerouslySetInnerHTML={{ __html: cookiePolicyContent }}></div>
        <p>&nbsp;</p>
      </div>
    </div>
  )
}

export default connect(
  () => ({
  }),
  {
    hideModal,
  },
)(CookiePolicyContainer)
