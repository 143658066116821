import React from 'react'
import ScorePill from '../uiKit/ScorePill'
import {formatDate} from '../../utils/formatDate'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
  
class ScoreSheetHeaderRow extends React.Component{

	render(){
		const {historyPolls,collapsedView,excludedPolls,excludePoll} = this.props
		return(
			<div className="scoreSheet-table-headerRow">		
				{historyPolls.map((poll,index) => {			 	 	
					if(poll.polls){ //is a setPOll
						let totalResponses=0
						let totalCorrect=0
						Object.keys(poll.polls).map((key)=>{
							const setPollPoll=poll.polls[key]
							if(!excludedPolls.setPollPolls[`${poll.id}-${key}`]){ //dont included excluded polls in calc
								if(setPollPoll.correctPercent>-1 && setPollPoll.aggregatedResponses){
									totalResponses+=setPollPoll.aggregatedResponses.totalResponses
									totalCorrect+=setPollPoll.aggregatedResponses.totalCorrectResponses
								}
							}
							return null
						})
						let percentageCorrectExcludingExcludedPolls=-1
						if(totalResponses!==0){
							percentageCorrectExcludingExcludedPolls=Math.round(100*totalCorrect/totalResponses)
						}
						if(!collapsedView){
				 	 	return(
				 	 		<ScoreSheetSetHeaderCellGroup excludePoll={excludePoll} excludedPolls={excludedPolls} percentageCorrectExcludingExcludedPolls={percentageCorrectExcludingExcludedPolls} percentageCorrect={poll.correctPercent} key={index} setPoll={poll} setActiveSetPollPoll={this.props.setActiveSetPollPoll}/>				 	 	
		  			)
				 	 }else{
				 	 	return(
				 	 		<ScoreSheetCollapsedSetHeaderCell excludePoll={()=>{excludePoll(poll.id,'setPolls')}} excludedPolls={excludedPolls} percentageCorrectExcludingExcludedPolls={percentageCorrectExcludingExcludedPolls} percentageCorrect={poll.correctPercent} key={index} setPoll={poll} setActiveSetPollPoll={this.props.setActiveSetPollPoll}/>
				 	 	)
				 	 }
					}else{	
				 	 	return(
				 	 			<ScoreSheetQuestionHeaderCell excludePoll={()=>{excludePoll(poll.id,'questionPolls')}} excludedPolls={excludedPolls} key={index} poll={poll} setActiveQuestionPoll={this.props.setActiveQuestionPoll}/>				 	 	
		  				)
						}	
					})}
			</div>
		)
	}
}

export default ScoreSheetHeaderRow



class ScoreSheetQuestionHeaderCell extends React.Component{

	render(){
		const {poll,setActiveQuestionPoll,excludedPolls,excludePoll} = this.props
		const isExcluded = excludedPolls.questionPolls[poll.id]
		return(
			<div className={'scoreSheet-table-headerRow-questionCell ' + (isExcluded ? 'scoreSheet-table-headerRow-questionCell--isExcluded' : '')} onClick={()=>{if(!isExcluded){setActiveQuestionPoll(poll)}else{excludePoll()}}}>
				<div className='scoreSheet-table-headerRow-questionCell-timestamp'>
					{formatDate(poll.minCaptured)}
				</div>
				<div className="scoreSheet-table-headerRow-questionCell-label notranslate">
					{getQuestionBodyLabel(poll.snapshot)}
				</div>
				<ScorePill disabled={isExcluded} score={poll.correctPercent} colorOnly={true} />
			</div>
		)
	} 
}

class ScoreSheetSetHeaderSetQuestionCell extends React.Component{

	render(){
		const {poll,setPoll,excludedPolls,excludePoll} = this.props		
		const isExcluded = excludedPolls.setPollPolls[`${setPoll.id}-${poll.id}`]
		if(poll.snapshot){
			return(
				<div className={'scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell' + (isExcluded ? ' scoreSheet-table-headerRow-setQuestionCell--isExcluded' : '')} onClick={()=>{if(!isExcluded){this.props.setActiveSetPollPoll()}else{excludePoll()}}}>
					<div className="scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell-label notranslate">
						{getQuestionBodyLabel(poll.snapshot)}
					</div>					
					<ScorePill disabled={isExcluded} score={poll.correctPercent} colorOnly={true} />
				</div>
			)
		}else return null
	}
}

class ScoreSheetSetHeaderCellGroup extends React.Component{

	render(){
		const {setPoll,excludePoll,percentageCorrect,excludedPolls,percentageCorrectExcludingExcludedPolls} = this.props
		const numberOfPolls = Object.keys(setPoll.polls).length
		const allSetPollsExcluded = excludedPolls.setPolls[setPoll.id]
		let someSetPollsExcluded=false
		Object.keys(excludedPolls.setPollPolls).map((key)=>{
			if(key.includes(setPoll.id)){
				someSetPollsExcluded=true
			}
			return null
		})

		let showPercentageCorrectExcludingExcludedPolls=false
		if( !allSetPollsExcluded && someSetPollsExcluded && setPoll.correctPercent!==-1 ){
			showPercentageCorrectExcludingExcludedPolls=true
		}	
		//if non or all setPollPolls are exclude, then show pollAveragePercentageCorrect
		//if some setPollPolls are excluded then also show the calculated percentageCorrect (which excluded excluded pollPolls from the calculation)
		return(
			<div style={{width: `calc(90px * ${numberOfPolls})`, minWidth: `calc(90px * ${numberOfPolls})`}} 
				className={'scoreSheet-table-headerRow-setHeaderCellGroup' +
					(numberOfPolls < 3 ? ' scoreSheet-table-headerRow-setHeaderCellGroup--narrow' : '') +
					(allSetPollsExcluded ? ' scoreSheet-table-headerRow-setHeaderCellGroup--isAllExcluded' : '')}
			>
				<div className='scoreSheet-table-headerRow-setHeaderCellGroup-rightBorder' />	
				<div className="scoreSheet-table-headerRow-setHeaderCellGroup-titleCell" onClick={()=>{this.props.setActiveSetPollPoll(setPoll)}}>			
					<div className="scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-name">
						{setPoll.snapshot.name}
					</div>
					<div className="scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-timestamp">
						{formatDate(setPoll.minCaptured)} 
					</div>	
					{setPoll.correctPercent > -1 &&
						<ScorePill disabled={allSetPollsExcluded || showPercentageCorrectExcludingExcludedPolls} score={percentageCorrect} typeDot />						
					}

					{showPercentageCorrectExcludingExcludedPolls && percentageCorrectExcludingExcludedPolls>-1 &&
						<div className='scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-modifiedScore'>
						{percentageCorrectExcludingExcludedPolls}<span className='scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-modifiedScore-percent'>%</span>
						</div>
					}
				</div>
				
				<div className="scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCellGroup">				
					{Object.keys(setPoll.polls).map((key,index) => {
						const poll=setPoll.polls[key]
					 	 return(						 	 	
					 	 	<ScoreSheetSetHeaderSetQuestionCell excludePoll={()=>{excludePoll(`${setPoll.id}-${poll.id}`,'setPollPolls',setPoll.id)}} excludedPolls={excludedPolls} key={index} poll={poll} setPoll={setPoll} setActiveQuestionPoll={this.props.setActiveQuestionPoll} setActiveSetPollPoll={()=>{this.props.setActiveSetPollPoll(setPoll,poll)}}/>            				
        				)
					 })}
				</div>
			</div>
		)
	}
}

class ScoreSheetCollapsedSetHeaderCell extends React.Component{
	render(){
		const {setPoll,percentageCorrect,excludedPolls,excludePoll} = this.props
		const allSetPollsExcluded = excludedPolls.setPolls[setPoll.id]
		return(
			<div className={'scoreSheet-table-headerRow-collapsedSetCell ' + (allSetPollsExcluded ? ' scoreSheet-table-headerRow-collapsedSetCell--isExcluded' : '') } onClick={()=>{if(!allSetPollsExcluded){this.props.setActiveSetPollPoll(setPoll)}else{excludePoll()}}}>
				<div className='scoreSheet-table-headerRow-collapsedSetCell-rightBorder' />
				<div className="scoreSheet-table-headerRow-collapsedSetCell-timestamp">
					{formatDate(setPoll.minCaptured)}
				</div>	
				<div className="scoreSheet-table-headerRow-collapsedSetCell-name">
					{setPoll.snapshot.name}
				</div>
				
				<ScorePill disabled={allSetPollsExcluded} score={percentageCorrect} colorOnly />
			</div>
		)
	}
}

