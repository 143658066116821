import React from 'react'
import { connect } from 'react-redux'
import {
  addQuestionAnimatedGIF, addChoiceAnimatedGIF, uploadQuestionAnimatedGIF, uploadChoiceAnimatedGIF,
} from '../../prosemirror/utils/editorActions/addAnimatedGIF'
import EditorInsertGIFModal from '../../components/richEditor/editorUI/modals/EditorInsertGIFModal'
import { gifSearch, fetchGifById } from '../../actions/imageSearch'
import { showNotification } from '../../actions/notifications'

const MAX_FILE_SIZE = 20000000

class EditorInsertGIFModalContainer extends React.Component {
  constructor() {
    super()
    this.handleSelectImage = this.handleSelectImage.bind(this)
    this.handleNewImageSearch = this.handleNewImageSearch.bind(this)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handleSelectFile = this.handleSelectFile.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.insertGifFromId = this.insertGifFromId.bind(this)

    this.state = ({
      submitLoading: false,
      images: [],
      searchTerm: '',
      isSearching: false,
      noResults: false,
      searchResultCount: null,
      count: 30,
      offset: 0,
    })
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    if (window.analytics) {
      window.analytics.page('Insert GIF Modal')
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    e.stopPropagation()
  }

  clearSearch() {
    this.setState({
      isSearching: false, images: [], searchTerm: '', offset: 0, searchResultCount: null, noResults: false,
    })
  }

  insertGifFromId(id) { // fetch gif from giphy id and insert it
    this.setState({ isSearching: true, noResults: false, submitLoading: true })
    this.props.fetchGifById(id).then((response) => {
      const obj = response.data
      const gifyID = id
      const giphyOriginalStill = obj.images.original_still.url
      const giphyOriginalGifURL = obj.images.original.url
      const giphySlug = obj.slug
      const giphyUser = obj.user
      const giphyOriginalWebPURL = obj.images.original.webp
      const attribution = obj.url
      const { title } = obj
      const width = parseInt(obj.images.original.width, 10)
      const height = parseInt(obj.images.original.height, 10)
      this.props.closeInsertImageModal()
      const {
        insertPos, isQuestion, questionPos, questionNode,
      } = this.props
      if (isQuestion) {
        this.props.addQuestionAnimatedGIF(gifyID, giphyOriginalStill, giphyOriginalGifURL, giphyOriginalWebPURL, giphySlug, giphyUser, attribution, title, width, height, insertPos, questionPos, questionNode)
      } else {
        this.props.addChoiceAnimatedGIF(gifyID, giphyOriginalStill, giphyOriginalGifURL, giphyOriginalWebPURL, giphySlug, giphyUser, attribution, title, width, height, insertPos)
      }
    })
  }

  handleNewImageSearch(searchTerm) {
    if (searchTerm) {
      if (window.analytics) {
        window.analytics.track('GIF search', {
          searchTerm,
        })
      }
      this.setState({ isSearching: true, noResults: false, images: [] })
      this.props.gifSearch(searchTerm, this.state.count, 0).then((response) => {
        if (response.data.length === 0) {
          this.setState({ noResults: true })
        }
        const images = []
        response.data.forEach((value) => {
          images.push(this.parseImageObject(value))
        })
        const totalMatches = response.pagination.total_count
        const { count } = this.state
        const nextOffset = response.pagination.offset + count
        this.setState({
          isSearching: false, images, searchTerm, offset: nextOffset, searchResultCount: totalMatches,
        })
      })
        .catch(() => {
          this.setState({
            isSearching: false, images: [], searchTerm, noResults: true,
          })
        })
    }
  }

  handleLoadMore() {
    const { searchTerm, count, offset } = this.state
    this.setState({ isSearching: true })
    this.props.gifSearch(searchTerm, count, offset).then((response) => {
      const { images } = this.state
      response.data.forEach((value) => {
        images.push(this.parseImageObject(value))
      })
      const totalMatches = response.pagination.total_count
      const { count } = this.state
      const nextOffset = response.pagination.offset + count
      this.setState({
        isSearching: false, images, offset: nextOffset, searchResultCount: totalMatches,
      })
    })
    if (window.analytics) {
      window.analytics.track('GIF search load more', {
        searchTerm,
      })
    }
  }

  parseImageObject(value) {
    return {
      src: value.images.downsized.url,
      gifyID: value.id,
      title: value.title,
      attribution: value.url,
      giphyOriginalStill: value.images.original_still.url,
      giphyOriginalGifURL: value.images.original.url,
      giphyOriginalWebPURL: value.images.original.webp,
      giphyGalleryWebPUrl: value.images.fixed_height.webp,
      giphyGalleryGifURL: value.images.fixed_height.url,
      // giphyDownsizedGalleryWeb:value.images['fixed_height_downsampled'].webp,
      giphySlug: value.slug,
      giphyUser: value.user,
      width: parseInt(value.images.original.width, 10),
      height: parseInt(value.images.original.height, 10),
      thumbnailWidth: parseInt(value.images.original.width, 10),
      thumbnailHeight: parseInt(value.images.original.height, 10),
      caption: '',
      accentColor: '',
    }
  }

  handleSelectImage(imageIndex) {
    if (!this.state.submitLoading) {
      this.setState({ submitLoading: true })
      const img = this.state.images[imageIndex]
      const {
        gifyID, giphyOriginalStill, giphyOriginalGifURL, giphySlug, giphyUser, giphyOriginalWebPURL, attribution, title, width, height,
      } = img
      this.props.closeInsertImageModal()
      const {
        insertPos, isQuestion, questionPos, questionNode,
      } = this.props
      if (isQuestion) {
        this.props.addQuestionAnimatedGIF(gifyID, giphyOriginalStill, giphyOriginalGifURL, giphyOriginalWebPURL, giphySlug, giphyUser, attribution, title, width, height, insertPos, questionPos, questionNode)
      } else {
        this.props.addChoiceAnimatedGIF(gifyID, giphyOriginalStill, giphyOriginalGifURL, giphyOriginalWebPURL, giphySlug, giphyUser, attribution, title, width, height, insertPos)
      }
    }
  }

  handleSelectFile(e) {
    const fileList = e.target.files
    const file = fileList[0]
    if (file.size > MAX_FILE_SIZE) {
      this.props.closeInsertImageModal()
      this.props.showNotification('The maximum file size you can upload is 20mb.', 'Sadly your GIF file is too large. ', 'destroy')
    } else {
      const {
        insertPos, isQuestion, questionPos, questionNode,
      } = this.props
      if (isQuestion) {
        this.props.uploadQuestionAnimatedGIF(insertPos, questionPos, questionNode, file)
      } else {
        this.props.uploadChoiceAnimatedGIF(insertPos, file)
      }
      this.props.closeInsertImageModal()
    }
  }

  render() {
    return (
      <div className='editor-insertImageModalContainer'>
        <EditorInsertGIFModal
          noResults={this.state.noResults}
          isSearching={this.state.isSearching}
          handleNewImageSearch={this.handleNewImageSearch}
          loadMore={this.handleLoadMore}
          searchTerm={this.state.searchTerm}
          onSelectImage={this.handleSelectImage}
          images={this.state.images}
          handleSelectFile={this.handleSelectFile}
          closeInsertImageModal={this.props.closeInsertImageModal}
          searchResultCount={this.state.searchResultCount}
          clearSearch={this.clearSearch}
          insertGifFromId={this.insertGifFromId}
        />
        <div onClick={this.props.closeInsertImageModal} className='editor-insertImageModalContainerBG' />
      </div>
    )
  }
}

function mapStateToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  {
    addQuestionAnimatedGIF,
    addChoiceAnimatedGIF,
    gifSearch,
    uploadQuestionAnimatedGIF,
    uploadChoiceAnimatedGIF,
    fetchGifById,
    showNotification,
  },
)(EditorInsertGIFModalContainer)
