const initialState = {
  currentModal: null,
  question: null,
  section: null,
  itemToPlay: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_ADD_LICENSE_KEY_MODAL':
      return {
        ...state,
        currentModal: 'ADD_LICENSE_KEY_MODAL',
      }

    case 'SHOW_PAYPAL_EMAIL_MODAL':
      return {
        ...state,
        currentModal: 'PAYPAL_EMAIL_MODAL',
      }
    case 'SHOW_NEW_SECTION_MODAL':
      return {
        ...state,
        currentModal: 'NEW_SECTION_MODAL',
      }

    case 'SHOW_SECTION_SELECTION_MODAL':
      return {
        ...state,
        currentModal: 'SECTION_SELECTION_MODAL',
        itemToPlay: action.item,
      }

    case 'SHOW_EDIT_STUDENT_MODAL':
      return {
        ...state,
        currentModal: 'EDIT_STUDENT_MODAL',
        student: action.student,
        section: action.section,
      }

    case 'SHOW_ASSIGN_SECTION_TO_DATA_OWNER_MODAL':
      return {
        ...state,
        currentModal: 'ASSIGN_SECTION_TO_DATA_OWNER_MODAL',
        section: action.section,
        userDataOwners: action.userDataOwners,
      }

    case 'SHOW_DATA_OWNER_INFO_MODAL':
      return {
        ...state,
        currentModal: 'DATA_OWNER_INFO_MODAL',
        dataOwner: action.dataOwner || {},
      }

    case 'SHOW_BULK_ADD_STUDENTS_MODAL':
      return {
        ...state,
        currentModal: 'BULK_ADD_STUDENTS_MODAL',
        section: action.section,
        availableCards: action.availableCards,
      }

    case 'SHOW_IMAGE_SEARCH_MODAL':
      return {
        ...state,
        currentModal: 'IMAGE_SEARCH_MODAL',
      }

    case 'SAVE_IMAGE':
      return {
        ...state,
        image: action.image,
        thumbnail: action.thumbnail,
      }

    case 'IMAGE_SEARCH_MODAL':
      return {
        ...state,
      }
    case 'FINISH_IMAGE_UPLOAD':
      return {
        ...state,
        image: null,
        thumbnail: null,
      }

    case 'SHOW_CONFIRM_MODAL':
      return {
        ...state,
        currentModal: 'CONFIRM_MODAL',
        confirmItem: action.confirmItem,
        accept: action.accept,
        actionType: action.actionType,
      }

    case 'SHOW_EDIT_FOLDER_MODAL':
      return {
        ...state,
        currentModal: 'EDIT_FOLDER_MODAL',
        folder: action.folder,
        parentFolder: action.parentFolder,
        newFolderSucess: action.newFolderSucess,
        repoId: action.repoId,
      }

    case 'SHOW_RENAME_SET_MODAL':
      return {
        ...state,
        currentModal: 'EDIT_SET_MODAL',
        set: action.set,

      }

    case 'SHOW_MOVE_TO_FOLDER_MODAL':
      return {
        ...state,
        currentModal: 'MOVE_TO_FOLDER_MODAL',
        itemsToMove: action.items,
        clearSelected: action.clearSelected,
      }

    case 'SHOW_EXTERNAL_SIGNIN_AUTH_MODAL':
      return {
        ...state,
        currentModal: 'EXTERNAL_SIGNIN_AUTH_MODAL',
        externalAccountType: action.externalAccountType,
        operationType: action.operationType,
      }

    case 'SHOW_ADD_PASSWORD_MODAL':
      return {
        ...state,
        currentModal: 'ADD_PASSWORD_MODAL',

      }

    case 'SHOW_LIVE_VIEW_ALERT_MODAL':
      return {
        ...state,
        currentModal: 'LIVE_VIEW_ALERT_MODAL',
      }

    case 'CONFIRM_EDIT_STUDENT_CARD_MODAL':
      return {
        ...state,
        currentModal: 'CONFIRM_EDIT_STUDENT_CARD_MODAL',
        selectedOption: action.selectedOption,
        student: action.student,
      }

    case 'SHOW_MANAGE_SECTIONS_MODAL':
      return {
        ...state,
        currentModal: 'MANAGE_SECTIONS_MODAL',
      }

    case 'SHOW_ADD_TO_QUEUE_FROM_LIBRARY_MODAL':
      return {
        ...state,
        currentModal: 'ADD_TO_QUEUE_FROM_LIBRARY_MODAL',
        sectionId: action.sectionId,
      }

    case 'SHOW_EXPANDED_REVIEW_SLIDE_MODAL':
      return {
        ...state,
        currentModal: 'EXPANDED_REVIEW_SLIDE_MODAL',
        poll: action.poll,
        processedResponses: action.processedResponses,
      }

    case 'SHOW_EXPANDED_QUESTION_SLIDE_MODAL':
      return {
        ...state,
        currentModal: 'EXPANDED_QUESTION_SLIDE_MODAL',
        question: action.question,
      }

    case 'SHOW_SCORESHEET_CUSTOM_RANGE_MODAL':
      return {
        ...state,
        currentModal: 'SCORESHEET_CUSTOM_RANGE_MODAL',
        currentSection: action.currentSection,

      }

    case 'SHOW_UPGRADE_MODAL':
      return {
        ...state,
        currentModal: 'UPGRADE_MODAL',
      }
    case 'SHOW_UPDATE_NAME_MODAL':
      return {
        ...state,
        currentModal: 'UPDATE_NAME_MODAL',
      }

    case 'SHOW_UPDATE_USERNAME_MODAL':
      return {
        ...state,
        currentModal: 'UPDATE_USERNAME_MODAL',
      }

    case 'SHOW_UPDATE_PAYMENT_INFO_MODAL':
      return {
        ...state,
        currentModal: 'UPDATE_PAYMENT_INFO_MODAL',
      }

    case 'SHOW_BILLING_INFO_MODAL':
      return {
        ...state,
        currentModal: 'BILLING_INFO_MODAL',
      }

    case 'SHOW_DOWNGRADE_PLAN_MODAL':
      return {
        ...state,
        currentModal: 'DOWNGRADE_PLAN_MODAL',
      }

    case 'SHOW_SWITCH_PLAN_MODAL':
      return {
        ...state,
        currentModal: 'SWITCH_PLAN_MODAL',
      }

    case 'SHOW_UPDATE_EMAIL_MODAL':
      return {
        ...state,
        currentModal: 'UPDATE_EMAIL_MODAL',
      }

    case 'SHOW_CHANGE_PASSWORD_MODAL':
      return {
        ...state,
        currentModal: 'CHANGE_PASSWORD_MODAL',
      }

    case 'SHOW_UPDATE_GOOGLE_MODAL':
      return {
        ...state,
        currentModal: 'UPDATE_GOOGLE_MODAL',

      }

    case 'SHOW_WHITELIST_ERROR_MODAL':
      return {
        ...state,
        currentModal: 'WHITELIST_ERROR_MODAL',
        whitelistErrorSource: action.source,
      }

    case 'SHOW_NEW_REPO_MODAL':
      return {
        ...state,
        currentModal: 'NEW_REPO_MODAL',
      }
    case 'SHOW_EDIT_REPO_MODAL':
      return {
        ...state,
        currentModal: 'EDIT_REPO_MODAL',
        repo: action.repo,
      }

    case 'SHOW_ADMIN_EDIT_REPO_NOTES_MODAL':
      return {
        ...state,
        currentModal: 'ADMIN_EDIT_REPO_NOTES_MODAL',
        repo: action.repo,
      }

    case 'SHOW_REPO_SELECTION_MODAL':
      return {
        ...state,
        currentModal: 'REPO_SELECTION_MODAL',
        itemToMove: action.item,
        multiSelectItemsToMove: action.multiSelectItems,
        navigateToRepo: action.navigateToRepo,
      }

    case 'SHOW_DUPLICATE_AND_EDIT_MODAL':
      return {
        ...state,
        currentModal: 'DUPLICATE_AND_EDIT_MODAL',
        itemToDuplicate: action.item,
      }
    case 'SHOW_EDIT_AUTHOR_DESCRIPTION_MODAL':
      return {
        ...state,
        currentModal: 'EDIT_AUTHOR_DESCRIPTION_MODAL',
      }

    case 'SHOW_ADMIN_EDIT_SUBMISSION_STATUS_MODAL':
      return {
        ...state,
        currentModal: 'ADMIN_EDIT_SUBMISSION_STATUS_MODAL',
        submission: action.submission,
      }

    case 'SHOW_DELETE_ACCOUNT_MODAL':
      return {
        ...state,
        currentModal: 'DELETE_ACCOUNT_MODAL',
      }

    case 'SHOW_VERIFY_PASSWORD_TO_SIGNIN_MODAL':
      return {
        ...state,
        currentModal: 'VERIFY_PASSWORD_TO_SIGNIN_MODAL',
        requestData: action.requestData,
        email: action.email,
      }

      case 'SHOW_COOKIE_POLICY_MODAL':
        return {
          ...state,
          currentModal: 'COOKIE_POLICY_MODAL',
        }

    case 'HIDE_MODAL':
      return {
        ...state,
        submission: null,
        itemToDuplicate: null,
        whitelistErrorSource: null,
        currentModal: null,
        externalAccountType: null,
        operationType: null,
        itemsToMove: null,
        folder: null,
        question: null,
        section: null,
        processedResponses: null,
        itemToPlay: null,
        parentFolder: null,
        student: null,
        confirmItem: null,
        accept: null,
        decline: null,
        availableCards: null,
        newFolderSucess: null,
        selectedOption: null,
        actionType: null,
        imageSearchResults: null,
        imageSearchTerm: null,
        thumbnail: null,
        set: null,
        clearSelected: null,
        sectionId: null,
        poll: null,
        currentSection: null,
        repoId: null,
        multiSelectItemsToMove: null,
        navigateToRepo: false,
        setId: null,
        requestData: null,
        email: null,
        userDataOwners: [],
        dataOwner: {},
      }

    case 'LOGOUT':
      return initialState

    default:
      return state
  }
}
