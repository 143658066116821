import React from 'react'
import YTReady from '../../../../youtubeReady'
import CanvasVideoControlBar from './CanvasVideoControlBar'
import EditorIcon from '../../../misc/EditorIcons'
import { deleteQuestionVideo } from '../../../../prosemirror/utils/editorActions/deleteQuestionVideo'
import { showEditVideoModal } from '../../utils/showEditVideoModal'
import { isContentAllowed } from '../../../../utils/cookieHelpers'
import { ContentBlocked, YoutubeBlocked } from '../../../misc/ContentBlocked'

class QuestionVideoReactComponent extends React.Component {
  constructor() {
    super()
    this.loadVideo = this.loadVideo.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onStateChange = this.onStateChange.bind(this)
    this.toggleMuteVideo = this.toggleMuteVideo.bind(this)
    this.updatePlayerCurrentTime = this.updatePlayerCurrentTime.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.seekTo = this.seekTo.bind(this)
    this.showEditVideoModal = this.showEditVideoModal.bind(this)
    this.onClipFinished = this.onClipFinished.bind(this)
    this.onVideoEnd = this.onVideoEnd.bind(this)
    this.restartVideo = this.restartVideo.bind(this)

    this.state = {
      playerReady: false,
      isPlaying: false,
      isMuted: false,
      playerCurrentTime: 0,
      isDraggingSeekBar: false,
      isBuffering: false,
      shouldShrinkVideoForControlBar: false,
      isYoutubeAllowed: true,
    }
    this.timer = null
  }

  componentDidMount() {
    const youtubeReady = YTReady
    youtubeReady.then((YT) => {
      this.loadVideo(YT)
    })
    const isYoutubeAllowed = isContentAllowed('youtube')
    this.setState({ isYoutubeAllowed })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isPlaying && !nextProps.isPlaying) {
      this.pauseVideo()
    }
  }

  componentWillUnmount() {
    this.stopTimer()
    if (this.state.isPlaying) {
      this.player.stopVideo()
    }
  }

  loadVideo(YT) {
    const { youtubeId } = this.props
    /* eslint-disable camelcase */
    this.player = new YT.Player(`youtube-player-${this.props.nodeId}`, {
      videoId: youtubeId,
      playerVars: {
        controls: 0, // disable controls
        // 'cc_load_policy': 1, // don't know what this does
        loop: 0, // loop video
        autoplay: 0, // autoplay
        fs: 0, // show full screen option
        disablekb: 1, // disable keyboard shortcuts
        rel: 0, // either hides related (depreciated) or only shows from same chanel
        iv_load_policy: 3, // don't show video annotations by default
        hl: 'en', // interface language
        cc_lang_pref: 'en',
        // don't know what these do, from EdPuzzle
        playsInline: 1,
        showinfo: 0, // undocumented, should hide title but I guess depreciated
        wmode: 'opaque', // undocumented
        start: (this.props.start),
        end: (this.props.end + 1),
      },

      host: 'http://www.youtube-nocookie.com',
      height: '100%',
      width: '100%',
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onStateChange,
        // onError:this.onError
      },
    })
    /* eslint-enable camelcase */
  }

  onPlayerReady() {
    const muted = this.player.isMuted()
    this.setState({ playerReady: true, isMuted: muted })
  }

  onStateChange(event) {
    switch (event.data) {
      case -1:// unstarted
        this.stopTimer()
        this.setState({ isBuffering: true })
        break
      case 0:// ended
        // this.setState({isBuffering:false,isPlaying:false,playerCurrentTime:this.props.end})
        this.setState({ isBuffering: false, playerCurrentTime: this.props.end })
        this.stopTimer()
        this.onVideoEnd()
        break
      case 1:// playing
        this.startTimer()
        this.setState({ isBuffering: false, isPlaying: true })
        this.props.playVideo()
        break
      case 2:// paused
        this.stopTimer()
        this.setState({ isPlaying: false, isBuffering: false })
        break
      case 3:// BUFFERING
        this.stopTimer()
        this.setState({ isBuffering: true })
        break
      default:
        break
    }
  }

  playVideo() {
    const playerState = this.player.getPlayerState()
    if (playerState === 0) {
      this.seekTo(this.props.start)
      this.setState({ playerCurrentTime: this.props.start })
    }
    this.setState({ isPlaying: true })
    this.player.playVideo()
  }

  pauseVideo() {
    this.setState({ isPlaying: false })
    this.player.pauseVideo()
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  updatePlayerCurrentTime() {
    if (!this.state.isBuffering) {
      const currentTime = this.player.getCurrentTime()
      if (currentTime > this.state.playerCurrentTime) {
        if (currentTime > this.props.end) {
          this.onClipFinished()
        } else {
          this.setState({ playerCurrentTime: currentTime })
        }
      }
    }
  }

  onClipFinished() {
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({ playerCurrentTime: this.props.start, isPlaying: false })
  }

  onVideoEnd() {
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({ playerCurrentTime: this.props.start })
  }

  seekTo(seconds) {
    this.setState({ isBuffering: true, playerCurrentTime: seconds })
    this.player.seekTo(seconds, true)
  }

  restartVideo() {
    this.setState({ isBuffering: true, playerCurrentTime: this.props.start })
    this.player.seekTo(this.props.start)
  }

  toggleMuteVideo() {
    const { player } = this
    if (player.isMuted()) {
      player.unMute()
    } else {
      player.mute()
    }
    this.setState({ isMuted: !player.isMuted() })
  }

  showEditVideoModal() {
    let videoNode
    let videoNodePos
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionVideo') {
            videoNode = questionNode
            videoNodePos = questionPos
          }
        })
      }
    })
    const isInsertFlow = false
    const isQuestion = true
    const media = {
      type: 'video',
      youtubeId: videoNode.attrs.youtubeId,
      fullDuration: videoNode.attrs.fullDuration,
      title: videoNode.attrs.title,
      channelTitle: videoNode.attrs.channelTitle,
      start: videoNode.attrs.start,
      end: videoNode.attrs.end,
    }
    const autoplay = false
    const searchState = null
    showEditVideoModal(isInsertFlow, searchState, autoplay, media, null, isQuestion, null, null, videoNode, videoNodePos)
  }

  render() {
    const { shouldShrinkVideoForControlBar, isYoutubeAllowed } = this.state

    const { slideWidth } = this.props
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    let mediaContainerWidth // container size
    let mediaContainerHeight // container size

    const slideTemplate = this.props.template

    if ((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft')) {
      mediaContainerWidth = 580 // should come from template definitions or somewhere
      mediaContainerHeight = 680 // should come from template definitions or somewhere
    }
    if (slideTemplate === 'bodyLeftMediaRightChoicesMedia') {
      mediaContainerWidth = 580 // should come from template definitions or somewhere
      mediaContainerHeight = 311 // should come from template definitions or somewhere
    }
    if (slideTemplate === 'bodyCenterMediaCenter') {
      mediaContainerWidth = 1160 // should come from template definitions or somewhere
      mediaContainerHeight = this.props.questionMediaDynamicHeight // pass in
    }

    // video dimensions should come from YouTube API?
    const intrinsicVideoWidth = 640
    const intrinsicVideoHeight = 360

    const intrinsicVideoAspect = intrinsicVideoWidth / intrinsicVideoHeight
    const mediaContainerAspect = mediaContainerWidth / mediaContainerHeight

    let videoContainerWidth
    let videoContainerHeight

    // if video is more-landscape than container
    if (intrinsicVideoAspect >= mediaContainerAspect) {
      videoContainerWidth = mediaContainerWidth
      videoContainerHeight = mediaContainerWidth / intrinsicVideoAspect
    }

    // if video is more-portrait than container
    if (intrinsicVideoAspect < mediaContainerAspect) {
      videoContainerHeight = mediaContainerHeight
      videoContainerWidth = mediaContainerHeight * intrinsicVideoAspect
    }

    const originalScaledVideoWidth = videoContainerWidth / inverseScale
    const originalScaledVideoHeight = (videoContainerWidth * (intrinsicVideoHeight / intrinsicVideoWidth)) / inverseScale

    const scaledVideoHeight = shouldShrinkVideoForControlBar ? (originalScaledVideoHeight - 35 - 2) : originalScaledVideoHeight // control bar height is 35px and 2px padding
    const scaledVideoWidth = scaledVideoHeight * (originalScaledVideoWidth / originalScaledVideoHeight)
    const videoScaleContainerLeft = shouldShrinkVideoForControlBar ? ((originalScaledVideoWidth - scaledVideoWidth) / 2) * inverseScale : 0

    // need to resize controlbar width
    // const controlBarWidth = videoContainerWidth / inverseScale
    const controlBarWidth = scaledVideoWidth
    const controlBarLeft = videoScaleContainerLeft

    // delete button needs to be aligned to video
    let deleteVideoYPosition
    let deleteVideoXPosition

    if ((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft')) {
      deleteVideoYPosition = 0
      deleteVideoXPosition = -12
    }
    if (slideTemplate === 'bodyCenterMediaCenter') {
      deleteVideoYPosition = (mediaContainerWidth - videoContainerWidth) / 2 - 6
      deleteVideoXPosition = 8
    }

    // adjust to offset to right of edge
    const deleteVideoAdjustedYPosition = deleteVideoYPosition - 12

    // const isSeeking = true
    const { isDraggingSeekBar } = this.state

    return (
      isYoutubeAllowed ? (
        <React.Fragment>
          <div
            style={{ width: `${videoContainerWidth}px`, height: `${videoContainerHeight}px` }}
            className={`slide-media--video-videoContainer ${isDraggingSeekBar ? ' slide-media--video-videoContainer--isSeeking' : ''}`}
            onMouseEnter={() => this.setState({ shouldShrinkVideoForControlBar: true })}
            onMouseLeave={() => this.setState({ shouldShrinkVideoForControlBar: false })}
          >
            <div
              className='slide-media--video-videoScaleContainer'
              style={{
                transform: `scale(${inverseScale})`,
                width: `${scaledVideoWidth}px`,
                height: `${scaledVideoHeight}px`,
                left: `${videoScaleContainerLeft}px`,
              }}
            >
              <div id={`youtube-player-${this.props.nodeId}`} className='slide-media--video-video' />
            </div>
            <div
              style={{ transform: `scale(${inverseScale})`, width: `${controlBarWidth}px`, left: `${controlBarLeft}px` }}
              className='slide-media--video-controlBarContainer'
            >
              {this.state.playerReady && (
              <CanvasVideoControlBar
                showEditVideoModal={this.showEditVideoModal}
                playVideo={this.playVideo}
                pauseVideo={this.pauseVideo}
                YTPlayer={this.player}
                clipStartTime={this.props.start}
                clipEndTime={this.props.end}
                duration={this.props.end - this.props.start}
                isPlaying={this.state.isPlaying}
                isMuted={this.state.isMuted}
                toggleMuteVideo={this.toggleMuteVideo}
                playerCurrentTime={this.state.playerCurrentTime}
                seekTo={this.seekTo}
                restartVideo={this.restartVideo}
                onBeforeChange={() => { this.setState({ isDraggingSeekBar: true }) }}
                onAfterChange={() => { this.setState({ isDraggingSeekBar: false }) }}
              />
              )}
            </div>
          </div>
          <button style={{ transform: `scale(${inverseScale})`, right: `${deleteVideoAdjustedYPosition}px`, top: `${deleteVideoXPosition}px` }} onClick={deleteQuestionVideo} className='slide-mediaContainer--video-deleteBtn'>
            <EditorIcon name='close' />
          </button>

        </React.Fragment>
      ) : (
        <YoutubeBlocked
          width={videoContainerWidth}
          height={videoContainerHeight}
          blockedContentName='Youtube Video'
          necessaryCookiesType='functional'
        />
      )
    )
  }
}

export default QuestionVideoReactComponent
