import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modals'
import NewSectionModal from '../../components/modals/NewSectionModal'
import SectionSelectionModal from '../../components/modals/SectionSelectionModal'
import EditStudentModal from '../../components/modals/EditStudentModal'
import AssignSectionToDataOwnerModal from '../../components/modals/AssignSectionToDataOwnerModal'
import DataOwnerInfoModal from '../../components/modals/DataOwnerInfoModal'
import BulkAddStudentsModal from '../../components/modals/BulkAddStudentsModal'
import ConfirmModal from '../../components/modals/ConfirmModal'
import EditFolderModal from '../../components/modals/EditFolderModal'
import EditSetModal from '../../components/modals/EditSetModal'
import MoveToFolderModal from '../../components/modals/MoveToFolderModal'
import ExternalSigninAuthModal from '../../components/modals/account/ExternalSigninAuthModal'
import AddPasswordModal from '../../components/modals/account/AddPasswordModal'
import LiveViewAlertModal from '../../components/modals/LiveViewAlertModal'
import ConfirmEditStudentCardModal from '../../components/modals/ConfirmEditStudentCardModal'
import ManageSectionsModal from '../../components/modals/ManageSectionsModal'
import SectionHomeAddToClassSearchModal from '../../components/modals/SectionHomeAddToClassSearchModal'
import ExpandedReviewSlideModal from '../../components/modals/ExpandedReviewSlideModal'
import ExpandedQuestionSlideModal from '../../components/modals/ExpandedQuestionSlideModal'
import UpdateNameModal from '../../components/modals/account/UpdateNameModal'
import UpdateUsernameModal from '../../components/modals/account/UpdateUsernameModal'
import UpdateEmailModal from '../../components/modals/account/UpdateEmailModal'
import ChangePasswordModal from '../../components/modals/account/ChangePasswordModal'
import UpdateGoogleModal from '../../components/modals/account/UpdateGoogleModal'
import UpdatePaymentInfoModal from '../../components/modals/account/UpdatePaymentInfoModal'
import BillingInfoModal from '../../components/modals/account/BillingInfoModal'
import DowngradePlanContainer from './account/DowngradePlanContainer'
import SwitchPlanContainer from './account/SwitchPlanContainer'
import DeleteAccountModal from '../../components/modals/account/DeleteAccountModal'
import AddLicenseKeyModal from '../../components/modals/account/AddLicenseKeyModal'
import PaypalEmailModal from '../../components/modals/account/PaypalEmailModal'
import WhitelistErrorModal from '../../components/modals/WhitelistErrorModal'
import EditRepoContainer from '../repo/EditRepoContainer'
import AdminEditRepoNotesContainer from '../explorer/AdminEditRepoNotesContainer'
import AdminEditSubmissionStatusContainer from '../explorer/AdminEditSubmissionStatusContainer'
import RepoSelectionModal from '../../components/modals/RepoSelectionModal'
import DuplicateAndEditModal from '../../components/modals/DuplicateAndEditModal'
import EditAuthorDescriptionContainer from '../authorPage/EditAuthorDescriptionContainer'
import ScoreSheetCustomRangeModal from '../../components/modals/ScoreSheetCustomRangeModal'
import VerifyPasswordToSigninModal from '../../components/modals/signin/VerifyPasswordToSigninModal'
import CookiePolicyModal from '../../components/modals/CookiePolicyModal'

// Modal Container decides which Modal to render based on the currentModal value in the store.
class ModalContainer extends Component {
  handleClose() {
    this.props.hideModal()
  }

  render() {
    const { externalAccountType, operationType } = this.props
    switch (this.props.currentModal) {
      case 'NEW_SECTION_MODAL':
        return <NewSectionModal hideModal={this.props.hideModal} />
      case 'SECTION_SELECTION_MODAL':
        return <SectionSelectionModal hideModal={this.props.hideModal} />
      case 'EDIT_STUDENT_MODAL':
        return <EditStudentModal hideModal={this.props.hideModal} student={this.props.student} section={this.props.section} />
      case 'ASSIGN_SECTION_TO_DATA_OWNER_MODAL':
        return (
          <AssignSectionToDataOwnerModal
            hideModal={this.props.hideModal}
            section={this.props.section}
            userDataOwners={this.props.userDataOwners}
          />
        )
      case 'DATA_OWNER_INFO_MODAL':
        return (
          <DataOwnerInfoModal
            hideModal={this.props.hideModal}
            dataOwner={this.props.dataOwner}
          />
        )
      case 'BULK_ADD_STUDENTS_MODAL':
        return <BulkAddStudentsModal hideModal={this.props.hideModal} section={this.props.section} availableCards={this.props.availableCards} />
      case 'CONFIRM_MODAL':
        return <ConfirmModal confirmItem={this.props.confirmItem} accept={this.props.accept} actionType={this.props.actionType} hideModal={this.props.hideModal} />
      case 'EDIT_FOLDER_MODAL':
        return <EditFolderModal hideModal={this.props.hideModal} newFolderSucess={this.props.newFolderSucess} folder={this.props.folder} parentFolder={this.props.parentFolder} repoId={this.props.repoId} />
      case 'MOVE_TO_FOLDER_MODAL':
        return <MoveToFolderModal hideModal={this.props.hideModal} />
      case 'EXTERNAL_SIGNIN_AUTH_MODAL':
        return (
          <ExternalSigninAuthModal
            hideModal={this.props.hideModal}
            externalAccountType={externalAccountType}
            operationType={operationType}
          />
        )
      case 'ADD_PASSWORD_MODAL':
        return <AddPasswordModal hideModal={this.props.hideModal} />
      case 'LIVE_VIEW_ALERT_MODAL':
        return <LiveViewAlertModal hideModal={this.props.hideModal} />
      case 'CONFIRM_EDIT_STUDENT_CARD_MODAL':
        return <ConfirmEditStudentCardModal hideModal={this.props.hideModal} student={this.props.student} selectedOption={this.props.selectedOption} />
      case 'MANAGE_SECTIONS_MODAL':
        return <ManageSectionsModal hideModal={this.props.hideModal} />
      case 'ADD_TO_QUEUE_FROM_LIBRARY_MODAL':
        return <SectionHomeAddToClassSearchModal hideModal={this.props.hideModal} />
      case 'EDIT_SET_MODAL':
        return <EditSetModal hideModal={this.props.hideModal} newFolderSucess={this.props.newFolderSucess} folder={this.props.folder} parentFolder={this.props.parentFolder} />
      case 'EXPANDED_REVIEW_SLIDE_MODAL':
        return <ExpandedReviewSlideModal hideModal={this.props.hideModal} poll={this.props.poll} processedResponses={this.props.processedResponses} />
      case 'EXPANDED_QUESTION_SLIDE_MODAL':
        return <ExpandedQuestionSlideModal hideModal={this.props.hideModal} question={this.props.question} />
      case 'SCORESHEET_CUSTOM_RANGE_MODAL':
        return <ScoreSheetCustomRangeModal hideModal={this.props.hideModal} />
      case 'UPDATE_NAME_MODAL':
        return <UpdateNameModal hideModal={this.props.hideModal} />
      case 'UPDATE_USERNAME_MODAL':
        return <UpdateUsernameModal hideModal={this.props.hideModal} />
      case 'UPDATE_PAYMENT_INFO_MODAL':
        return <UpdatePaymentInfoModal hideModal={this.props.hideModal} />
      case 'BILLING_INFO_MODAL':
        return <BillingInfoModal hideModal={this.props.hideModal} />
      case 'DOWNGRADE_PLAN_MODAL':
        return <DowngradePlanContainer hideModal={this.props.hideModal} />
      case 'SWITCH_PLAN_MODAL':
        return <SwitchPlanContainer hideModal={this.props.hideModal} />
      case 'UPDATE_EMAIL_MODAL':
        return <UpdateEmailModal hideModal={this.props.hideModal} />
      case 'CHANGE_PASSWORD_MODAL':
        return <ChangePasswordModal hideModal={this.props.hideModal} />
      case 'DELETE_ACCOUNT_MODAL':
        return <DeleteAccountModal hideModal={this.props.hideModal} />
      case 'UPDATE_GOOGLE_MODAL':
        return <UpdateGoogleModal hideModal={this.props.hideModal} />
      case 'WHITELIST_ERROR_MODAL':
        return <WhitelistErrorModal whitelistErrorSource={this.props.whitelistErrorSource} hideModal={this.props.hideModal} />
      case 'NEW_REPO_MODAL':
        return <EditRepoContainer hideModal={this.props.hideModal} />
      case 'EDIT_REPO_MODAL':
        return <EditRepoContainer hideModal={this.props.hideModal} repo={this.props.repo} />
      case 'ADMIN_EDIT_REPO_NOTES_MODAL':
        return <AdminEditRepoNotesContainer hideModal={this.props.hideModal} repo={this.props.repo} />
      case 'REPO_SELECTION_MODAL':
        return <RepoSelectionModal hideModal={this.props.hideModal} />
      case 'ADD_LICENSE_KEY_MODAL':
        return <AddLicenseKeyModal hideModal={this.props.hideModal} />
      case 'PAYPAL_EMAIL_MODAL':
        return <PaypalEmailModal hideModal={this.props.hideModal} />
      case 'DUPLICATE_AND_EDIT_MODAL':
        return <DuplicateAndEditModal hideModal={this.props.hideModal} />
      case 'EDIT_AUTHOR_DESCRIPTION_MODAL':
        return <EditAuthorDescriptionContainer hideModal={this.props.hideModal} />
      case 'ADMIN_EDIT_SUBMISSION_STATUS_MODAL':
        return <AdminEditSubmissionStatusContainer submission={this.props.submission} hideModal={this.props.hideModal} />
      case 'VERIFY_PASSWORD_TO_SIGNIN_MODAL':
        return (
          <VerifyPasswordToSigninModal
            hideModal={this.props.hideModal}
            requestData={this.props.requestData}
            email={this.props.email}
          />
        )
      case 'COOKIE_POLICY_MODAL':
        return <CookiePolicyModal hideModal={this.props.hideModal} />
      default:
        return null
    }
  }
}

export default connect(
  (state) => ({
    currentModal: state.modals.currentModal,
    question: state.modals.question,
    parentFolder: state.modals.parentFolder,
    student: state.modals.student,
    section: state.modals.section,
    availableCards: state.modals.availableCards,
    set: state.modals.set,
    questionIndex: state.modals.index,
    confirmItem: state.modals.confirmItem,
    accept: state.modals.accept,
    actionType: state.modals.actionType,
    folder: state.modals.folder,
    newFolderSucess: state.modals.newFolderSucess,
    externalAccountType: state.modals.externalAccountType,
    operationType: state.modals.operationType,
    selectedOption: state.modals.selectedOption,
    poll: state.modals.poll,
    processedResponses: state.modals.processedResponses,
    whitelistErrorSource: state.modals.whitelistErrorSource,
    repoId: state.modals.repoId,
    repo: state.modals.repo,
    submission: state.modals.submission,
    requestData: state.modals.requestData,
    email: state.modals.email,
    userDataOwners: state.modals.userDataOwners,
    dataOwner: state.modals.dataOwner,
  }),
  { hideModal },
)(ModalContainer)
