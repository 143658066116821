import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import AccountSubsection from './AccountSubsection'
import AccountButton from './AccountButton'
import AccountServiceStatus from './AccountServiceStatus'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import { getUserDisplayName } from '../../utils/userDisplayName'
import ProfilePic from '../ProfilePic'
import Icon from '../misc/Icons'
import { isUnderCcpa, revisitConsent, getCookieSettingsButtonText } from '../../utils/cookieHelpers'

class AccountPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cookieSettingsButtonText: '',
      isCcpaActive: false,
    }
  }

  componentDidMount() {
    this.setState({
      isCcpaActive: isUnderCcpa(),
      cookieSettingsButtonText: getCookieSettingsButtonText(),
    })
  }

  showUpgrade() {
    window.location.hash = 'upgrade'
  }
  render() {
    const {
      user,
      service,
      billingInfo,
      signinMethods,
      userDataOwners,
      uploadingImage,
      hasClaimedSummerCoupon,
      showExternalSigninAuthModal,
      showDataOwnerInfoModal,
      showCookiePolicyModal,
    } = this.props

    const {
      cookieSettingsButtonText,
      isCcpaActive,
    } = this.state

    const displayedName = getUserDisplayName(user)

    let userHasGoogleOnly = false
    let userHasAppleOnly = false
    let userHasEmailPasswordOnly = false
    let userHasGoogleAndPassword = false
    let userHasAppleAndPassword = false

    if (signinMethods) {
      if (signinMethods.password) {
        if (signinMethods.google) {
          userHasGoogleAndPassword = true
        } else if (signinMethods.apple) {
          userHasAppleAndPassword = true
        } else userHasEmailPasswordOnly = true
      } else if (signinMethods.google) {
        userHasGoogleOnly = true
      } else if (signinMethods.apple) {
        userHasAppleOnly = true
      }
    }

    let accountServiceStatus
    if (service.pass || service.providedBy) {
      accountServiceStatus = 'pass'
    } else if (service.product === 'free') {
      accountServiceStatus = 'free'
    } else if (service.product === 'plus') {
      accountServiceStatus = service.status
    }

    const payPalEmail = user.payoutDestination

    return (
      <React.Fragment>

        <TopPageHeader
          key='topHeader'
          location='account'
          height={130}
          header={(
            <React.Fragment>
              <div className='pageHeaderTop--account-headerRow-profilePicContainer'>

                {!user.image && !uploadingImage && (
                <React.Fragment>
                  <input
                    id='file'
                    type='file'
                    onChange={this.props.handleFileUpload}
                    value=''
                    style={{
                      position: 'absolute', opacity: '0', height: '0', width: '0',
                    }}
                    name='uploadProfilePic'
                    accept='.png,.jpg,.jpeg,.tif,.tiff'
                  />
                  <label htmlFor='file'>
                    <div className='pageHeaderTop--account-headerRow-button pageHeaderTop--account-headerRow-button--uploadPic'>
                      <Icon name='plus' />
                    </div>
                  </label>
                </React.Fragment>
                )}

                {user.image && !uploadingImage && (
                <div className='pageHeaderTop--account-headerRow-button pageHeaderTop--account-headerRow-button--removePic' onClick={this.props.handleRemoveProfileImage}>
                  <Icon name='circle-with-close' />
                </div>
                )}

                {uploadingImage && (
                <div className='pageHeaderTop--account-headerRow-profilePicUploading'>
                  <div className='pageHeaderTop--account-headerRow-profilePicUploading-loadingSpinnerContainer'>
                    <div className='pageHeaderTop--account-headerRow-profilePicUploading-loadingSpinner' />
                  </div>
                </div>
                )}

                {!uploadingImage &&
                <ProfilePic size='56' borderRadius='6' user={user} />}

              </div>
              {user.firstName || ''}
              {' '}
              {user.lastName || ''}
            </React.Fragment>
    )}
        />

        <AccountSubsection label='Display Name'>
          {displayedName}
          <div className='account-subsection-actionRow'>
            <AccountButton label='Update Name' onClickFunction={this.props.showUpdateNameModal} />
          </div>
        </AccountSubsection>

        {user.username && (
        <AccountSubsection label='Creator Account'>
          Username
          {' '}
          <span className='account-subsection-light'>{user.username}</span>

          <NavLink
            to={`/${user.username}`}
            className='account-subsection-creatorURL'
          >
            {`${window.location.host}/${user.username}`}
          </NavLink>

          <AccountButton label='Edit Username' subtle onClickFunction={this.props.showUpdateUsernameModal} />

          {!payPalEmail &&
          <AccountButton label='Add PayPal Email for Creator Fund Payouts' onClickFunction={this.props.showPaypalEmailModal} />}
          {payPalEmail && (
          <React.Fragment>
            <div className='account-subsection-actionRow'>
              PayPal Account Email&nbsp;
              <span className='account-subsection-light'>{payPalEmail}</span>
            </div>
            <AccountButton label='Edit PayPal Account Email' subtle onClickFunction={this.props.showPaypalEmailModal} />
          </React.Fragment>
          )}

        </AccountSubsection>
        )}

        {!!userDataOwners.length && (
          <AccountSubsection label='org connect'>
            {userDataOwners.map((dataOwner) => (
              <div
                className='account-subsection--dataOwner-row'
                onClick={() => showDataOwnerInfoModal(dataOwner)}
                key={dataOwner.id}
              >
                <span
                  className='account-subsection--dataOwner-row-dataOwnerName'
                >
                  {dataOwner.name}
                </span>
              </div>
            ))}
          </AccountSubsection>
        )}

        <AccountServiceStatus
          accountServiceStatus={accountServiceStatus}
          service={service}
          showDowngradePlanModal={this.props.showDowngradePlanModal}
          billingInfo={billingInfo}
          upcomingInvoice={this.props.upcomingInvoice}
          showUpdatePaymentInfoModal={this.props.showUpdatePaymentInfoModal}
          showBillingInfoModal={this.props.showBillingInfoModal}
          hasClaimedSummerCoupon={hasClaimedSummerCoupon}
          showSwitchPlanModal={this.props.showSwitchPlanModal}
          showAddLicenseKeyModal={this.props.showAddLicenseKeyModal}
        />

        {userHasEmailPasswordOnly && (
        <AccountSubsection
          label='Sign in and Authentication'
          name='authentication--emailPasswordOnly'
        >

          Email Address
          {' '}
          <span className='account-subsection-light'>{user.email}</span>

          <div className='account-subsection-actionRow'>
            <AccountButton label='Update Email Address' onClickFunction={this.props.showUpdateEmailModal} />
            <AccountButton label='Edit Password' onClickFunction={this.props.showChangePasswordModal} />
            <AccountButton label='Add Sign in with Google' onClickFunction={() => showExternalSigninAuthModal('google', 'add')} />
            <AccountButton label='Add Sign in with Apple' onClickFunction={() => showExternalSigninAuthModal('apple', 'add')} />
          </div>
        </AccountSubsection>
        )}

        {userHasGoogleOnly && (
        <AccountSubsection
          label='Sign in and Authentication'
          name='authentication--googleOnly'
        >

          Sign in with Google
          {' '}
          <span className='account-subsection-light'>{user.email}</span>

          <div className='account-subsection-actionRow'>
            <AccountButton label='Change Google Account' onClickFunction={this.props.showUpdateGoogleModal} />
            <AccountButton label='Add Password' onClickFunction={this.props.showAddPasswordModal} />
          </div>

        </AccountSubsection>
        )}

        {userHasAppleOnly && (
        <AccountSubsection
          label='Sign in and Authentication'
          name='authentication--googleOnly'
        >

          Sign in with Apple
          {' '}
          <span className='account-subsection-light'>{user.email}</span>

          <div className='account-subsection-actionRow'>

            <AccountButton label='Add Password' onClickFunction={this.props.showAddPasswordModal} />
          </div>

        </AccountSubsection>
        )}

        {userHasGoogleAndPassword && (
        <AccountSubsection
          label='Sign in and Authentication'
          name='authentication--googleAndEmailPassword'
        >
          Email Address
          {' '}
          <span className='account-subsection-light'>{user.email}</span>
          <AccountButton name='editPassword' label='Edit Password' onClickFunction={this.props.showChangePasswordModal} />

          Sign in with Google
          {' '}
          <span className='account-subsection-light'>{user.email}</span>
          <AccountButton name='removeGoogle' label='Remove Sign in with Google' onClickFunction={() => { showExternalSigninAuthModal('google', 'remove') }} />

        </AccountSubsection>
        )}

        {userHasAppleAndPassword && (
        <AccountSubsection
          label='Sign in and Authentication'
          name='authentication--googleAndEmailPassword'
        >
          Email Address
          {' '}
          <span className='account-subsection-light'>{user.email}</span>
          <AccountButton name='editPassword' label='Edit Password' onClickFunction={this.props.showChangePasswordModal} />

          Sign in with Apple
          {' '}
          <span className='account-subsection-light'>{user.email}</span>
          <AccountButton name='removeApple' label='Remove Sign in with Apple' onClickFunction={() => { showExternalSigninAuthModal('apple', 'remove') }} />

        </AccountSubsection>
        )}

        <AccountSubsection
          label='Help Links'
          name='quickLinks'
        >
          <div className='account-subsection--quickLinks-colContainer'>
            <div className='account-subsection--quickLinks-col'>
              <AccountButton label='Plickers Help Home' onClickFunction={() => { window.open('https://help.plickers.com', '_blank') }} />
              <AccountButton label='Getting Started Guide' onClickFunction={() => { window.open('https://help.plickers.com/hc/en-us/categories/1260801472210-Getting-Started', '_blank') }} />
              <AccountButton label='Contact Support' onClickFunction={() => { window.open('https://help.plickers.com/hc/en-us/requests/new', '_blank') }} />
            </div>
            <div className='account-subsection--quickLinks-col'>
              <AccountButton label='Get Plickers Cards' onClickFunction={() => { window.open('https://help.plickers.com/hc/en-us/articles/360008948034-Get-Plickers-Cards', '_blank') }} />
              <AccountButton label='Get iOS App' onClickFunction={() => { window.open('https://itunes.apple.com/us/app/plickers/id701184049?mt=8', '_blank') }} />
              <AccountButton label='Get Android App' onClickFunction={() => { window.open('https://play.google.com/store/apps/details?id=com.plickers.client.android', '_blank') }} />
            </div>
            <div className='account-subsection--quickLinks-col'>
              <AccountButton label='Privacy Policy' onClickFunction={() => { window.open('https://help.plickers.com/hc/en-us/articles/360009090833-Plickers-Privacy-Policy', '_blank') }} />
              <AccountButton label='Terms of Service' onClickFunction={() => { window.open('https://help.plickers.com/hc/en-us/articles/360009090913-Terms-of-Service', '_blank') }} />
              <AccountButton label='Cookie Policy' onClickFunction={showCookiePolicyModal} />
            </div>
          </div>
          <div className='account-subsection--quickLinks-colContainer'>
              <AccountButton
                label={cookieSettingsButtonText}
                onClickFunction={revisitConsent}
                icon={isCcpaActive ? 'ccpa' : null}
              />
          </div>
        </AccountSubsection>

        <div className='account-footer-colContainer'>
          <div className='account-footer-col'>
            <AccountButton name='signOut' label='Sign out of Plickers' onClickFunction={this.props.logout} />
          </div>
          <div className='account-footer-col'>
            <AccountButton name='deleteAccount' label='Delete Account' onClickFunction={this.props.showDeleteAccountModal} />
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default withRouter(AccountPage)
