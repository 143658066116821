import React from 'react'
import filter from 'lodash/filter'
import StaticLetterBlock from './StaticLetterBlock'
import createMarkup from '../../utils/createMarkup'

// QUESTION MEDIA
import StaticSlideImage from './media/image/StaticSlideImage'
import StaticSlideZoomableFitImage from './media/image/StaticSlideZoomableFitImage'
import StaticSlideZoomableFillImage from './media/image/StaticSlideZoomableFillImage'
import StaticSlideAnimatedGIF from './media/gif/StaticSlideAnimatedGIF'
import StaticSlideZoomableFitAnimatedGIF from './media/gif/StaticSlideZoomableFitAnimatedGIF'
import StaticSlideZoomableFillAnimatedGIF from './media/gif/StaticSlideZoomableFillAnimatedGIF'
import StaticSlideSoundPlayer from './media/sound/StaticSlideSoundPlayer'
import StaticSlideSoundZoomablePlayer from './media/sound/StaticSlideSoundZoomablePlayer'
import StaticSlideSoundStatic from './media/sound/StaticSlideSoundStatic'
import StaticSlideVideoPlayer from './media/video/StaticSlideVideoPlayer'
import StaticSlideVideoZoomablePlayer from './media/video/StaticSlideVideoZoomablePlayer'
import StaticSlideVideoStatic from './media/video/StaticSlideVideoStatic'

// CHOICE MEDIA
import StaticSlideChoiceImage from './media/image/StaticSlideChoiceImage'
import StaticSlideChoiceZoomableFitImage from './media/image/StaticSlideChoiceZoomableFitImage'
import StaticSlideChoiceZoomableFillImage from './media/image/StaticSlideChoiceZoomableFillImage'
import StaticSlideChoiceAnimatedGIF from './media/gif/StaticSlideChoiceAnimatedGIF'
import StaticSlideChoiceZoomableAnimatedGIF from './media/gif/StaticSlideChoiceZoomableAnimatedGIF'
import StaticSlideChoiceSoundPlayer from './media/sound/StaticSlideChoiceSoundPlayer'
import StaticSlideChoiceSoundZoomablePlayer from './media/sound/StaticSlideChoiceSoundZoomablePlayer'
import StaticSlideChoiceSoundStatic from './media/sound/StaticSlideChoiceSoundStatic'
import StaticSlideChoiceVideoPlayer from './media/video/StaticSlideChoiceVideoPlayer'
import StaticSlideChoiceVideoZoomablePlayer from './media/video/StaticSlideChoiceVideoZoomablePlayer'
import StaticSlideChoiceVideoStatic from './media/video/StaticSlideChoiceVideoStatic'
import FontSizeCalculatorComponent from '../fontSizeCalculator/FontSizeCalculatorComponent'

import { isContentAllowed } from '../../utils/cookieHelpers'
import { ContentBlocked, YoutubeBlocked } from '../misc/ContentBlocked'

function isSurveyQuestion(question) {
  if (question && question.choices) {
    if (filter(question.choices, { correct: true }).length !== 0) {
      return false
    } return true
  } return null
}

const DEFAULT_SLIDE_WIDTH = 1280

class RichStaticSlide extends React.Component {
  constructor(props) {
    super(props)
    this.updateQuestionFontSizes = this.updateQuestionFontSizes.bind(this)
    this.updateQuestionMediaDynamicHeight = this.updateQuestionMediaDynamicHeight.bind(this)
    this.onMouseEnterChoice = this.onMouseEnterChoice.bind(this)
    this.onMouseEnterChoicesGroup = this.onMouseEnterChoicesGroup.bind(this)
    this.onMouseLeaveChoicesGroup = this.onMouseLeaveChoicesGroup.bind(this)
    if (props.slideGenerationFinished) { // for slide service
      this.loadMediaPromises = []
      const questionMedia = props.question.media
      if (questionMedia) {
        const questionMediaPromise = new Promise(((resolve) => {
          this.questionMediaPromiseResolve = resolve
        }))
        this.loadMediaPromises.push(questionMediaPromise)
      }
      if (props.question.layout === 'bodyCenterChoicesMedia' || props.question.layout === 'bodyLeftMediaRightChoicesMedia') {
        props.question.choices.forEach((choice, i) => {
          if (choice.media) {
            const choicePromise = new Promise(((resolve) => {
              this[`choice${i}MediaPromiseResolve`] = resolve
            }))
            this.loadMediaPromises.push(choicePromise)
          }
        })
      }
    }

    let bodyFontSize = 10
    let choiceFontSize = 10
    let questionMediaDynamicHeight = 0
    if (props.question.measurements) {
      bodyFontSize = props.question.measurements.bodyFS
      choiceFontSize = props.question.measurements.choiceFS
      questionMediaDynamicHeight = props.question.measurements.questionMediaDynamicHeight
    }
    this.state = {
      bodyFontSize,
      choiceFontSize,
      questionMediaDynamicHeight,
      isYoutubeAllowed: true,
    }
  }

  componentDidMount() {
    if (this.props.slideGenerationFinished) {
      Promise.all(this.loadMediaPromises).then(() => {
        this.props.slideGenerationFinished()
      })
    }
    const isYoutubeAllowed = isContentAllowed('youtube')
    this.setState({ isYoutubeAllowed })
  }

  onMouseEnterChoice(choiceLetter) { // For sync live view where we highlight students who responded A on mouse over choice A
    if (this.props.onMouseEnterChoice) {
      this.props.onMouseEnterChoice(choiceLetter)
    }
  }

  onMouseEnterChoicesGroup() { // For sync live view
    if (this.props.onMouseEnterChoicesGroup) {
      this.props.onMouseEnterChoicesGroup()
    }
  }

  onMouseLeaveChoicesGroup() { // For sync live view
    if (this.props.onMouseLeaveChoicesGroup) {
      this.props.onMouseLeaveChoicesGroup()
    }
  }

  updateQuestionFontSizes(perfectCombo) {
    this.setState({ bodyFontSize: perfectCombo.bodyFontSize })
    this.setState({ choiceFontSize: perfectCombo.choiceFontSize })
    if (this.props.onCalculateFontSizes) {
      this.props.onCalculateFontSizes(perfectCombo)
    }
  }

  updateQuestionMediaDynamicHeight(questionMediaDynamicHeight) {
    this.setState({ questionMediaDynamicHeight })
    if (this.props.onCalculateQuestionMediaDynamicHeight) {
      this.props.onCalculateQuestionMediaDynamicHeight(Math.max(questionMediaDynamicHeight, 0))
    }
  }

  render() {
    const {
      question,
      slideWidth,
      mediaIsPlayable,
      mediaIsZoomable,
      choiceMediaZoomed,
      activeZoomedChoiceIndex,
      isAskScreen,
      isScanScreen,
      showResponseCounts,
      showGraph,
      colorScheme,
      processedResponses,
      totalResponses,
      nowPlayingView,
      reportView,
      expectedResponses,
    } = this.props

    const { isYoutubeAllowed } = this.state

    const slideScale = slideWidth / DEFAULT_SLIDE_WIDTH
    const inverseScale = 1 / slideScale
    // prevent and other elements icons getting too big
    const ceilingInverseScale = 1.55
    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

    const bodyHTML = question.bodyHtml
    const slideTemplate = question.layout
    const isSurvey = isSurveyQuestion(question)

    let bodyFontSize
    let choiceFontSize
    let questionMediaDynamicHeight
    if (question.measurements) { // use this instead of state for the FS visualiser where xLayout is recalculated
      bodyFontSize = question.measurements.bodyFS
      choiceFontSize = question.measurements.choiceFS
      questionMediaDynamicHeight = question.measurements.questionMediaDynamicHeight
    } else {
      bodyFontSize = this.state.bodyFontSize
      choiceFontSize = this.state.choiceFontSize
      questionMediaDynamicHeight = this.state.questionMediaDynamicHeight
    }

    let bodyMediaType
    const { media } = question
    const hasBodyMedia = !!media
    if (hasBodyMedia && media.type === 'image') {
      bodyMediaType = 'image'
    }
    if (hasBodyMedia && media.type === 'animatedGif') {
      bodyMediaType = 'animatedGif'
    } else if (hasBodyMedia && media.type === 'sound') { // TODO -clipping
      bodyMediaType = 'sound'
    } else if (hasBodyMedia && media.type === 'video') {
      bodyMediaType = 'video'
    }

    const templateDefinition = slideTemplate // taken from Main React getTemplateDefinition
    const isChoiceMedia = (slideTemplate === 'bodyCenterChoicesMedia' || slideTemplate === 'bodyLeftMediaRightChoicesMedia')

    const choiceCount = question.choices.length
    // ToDo: bring in from slide definitions
    let mediaChoiceWidth = 285
    if (choiceCount === 3) {
      mediaChoiceWidth = 365
    }
    if (choiceCount === 2) {
      mediaChoiceWidth = 505
    }

    let choiceStyle
    if (isChoiceMedia) {
      choiceStyle = { width: `${mediaChoiceWidth}px` }
    }
    // ZOOM CHOICES MEDIA

    const slideChoicesStyle = {
      position: 'relative',
      zIndex: choiceMediaZoomed ? 9000 : 0,
    }
    const { alwaysShowLetterblock, showCorrect, isReviewScreen } = this.props

    return (
      <div style={{ transform: `scale(${slideScale})` }} className={`slide notranslate slide--static${templateDefinition ? ` slide--template--${templateDefinition}` : ''}${reportView ? ' slide--reportView ' : ''}`}>
        {/* BODY */}
        <div dangerouslySetInnerHTML={createMarkup(bodyHTML)} className='slide-body' style={{ fontSize: `${bodyFontSize}px` }} />
        {/* MEDIA */}
        {hasBodyMedia && bodyMediaType === 'image' && !mediaIsZoomable && (
        <div className='slide-mediaContainer slide-mediaContainer--image'>
          <StaticSlideImage
            questionMediaPromiseResolve={this.questionMediaPromiseResolve}
            image={media}
            key={media.fileId}
            UIInverseScale={UIInverseScale}
            slideTemplate={question.layout}
            questionMediaDynamicHeight={questionMediaDynamicHeight}
            imageFillType={question.media.imageFill ? 'fill' : 'fit'}
          />
        </div>
        )}

        {hasBodyMedia && bodyMediaType === 'image' && mediaIsZoomable && !question.media.imageFill && (
        <div className='slide-mediaContainer slide-mediaContainer--image'>
          <StaticSlideZoomableFitImage
            image={media}
            slideTemplate={question.layout}
            questionMediaDynamicHeight={questionMediaDynamicHeight}
          />
        </div>
        )}

        {hasBodyMedia && bodyMediaType === 'animatedGif' && mediaIsZoomable && !question.media.imageFill && (
        <div className='slide-mediaContainer slide-mediaContainer--image'>
          <StaticSlideZoomableFitAnimatedGIF
            gif={media}
            slideTemplate={question.layout}
            questionMediaDynamicHeight={questionMediaDynamicHeight}
          />
        </div>
        )}

        {hasBodyMedia && bodyMediaType === 'image' && mediaIsZoomable && question.media.imageFill && (
        <div className='slide-mediaContainer slide-mediaContainer--image'>
          <StaticSlideZoomableFillImage
            slideTemplate={question.layout}
            questionMediaDynamicHeight={questionMediaDynamicHeight}
          />
        </div>
        )}

        {hasBodyMedia && bodyMediaType === 'animatedGif' && mediaIsZoomable && question.media.imageFill && (
        <div className='slide-mediaContainer slide-mediaContainer--image'>
          <StaticSlideZoomableFillAnimatedGIF
            slideTemplate={question.layout}
            questionMediaDynamicHeight={questionMediaDynamicHeight}
          />
        </div>
        )}

        {hasBodyMedia && bodyMediaType === 'animatedGif' && !mediaIsZoomable && media.gifUrl && (
        <div className='slide-mediaContainer slide-mediaContainer--gif'>
          <StaticSlideAnimatedGIF
            questionMediaPromiseResolve={this.questionMediaPromiseResolve}
            gif={media}
            fullyStatic={!mediaIsPlayable}
            UIInverseScale={UIInverseScale}
            slideTemplate={question.layout}
            questionMediaDynamicHeight={questionMediaDynamicHeight}
            gifFillType={question.media.imageFill ? 'fill' : 'fit'}
          />
        </div>
        )}

        {bodyMediaType === 'sound' && mediaIsPlayable && !mediaIsZoomable && (
        <div className='slide-mediaContainer slide-mediaContainer--sound'>
          <StaticSlideSoundPlayer
            sound={media}
            slideWidth={slideWidth}
            slideTemplate={question.layout}
            showWaveform // should come from question
            viewportWidth={this.props.viewportWidth}
            viewportHeight={this.props.viewportHeight}
            UIInverseScale={UIInverseScale}
            hideSoundTitles={this.props.hideSoundTitles}
          />
        </div>
        )}

        {bodyMediaType === 'sound' && mediaIsPlayable && mediaIsZoomable && (
        <div className='slide-mediaContainer slide-mediaContainer--sound'>
          <StaticSlideSoundZoomablePlayer
            slideWidth={slideWidth}
            slideTemplate={question.layout}
            UIInverseScale={UIInverseScale}
          />
        </div>
        )}

        {bodyMediaType === 'sound' && !mediaIsPlayable && (
        <div className='slide-mediaContainer slide-mediaContainer--sound'>
          <StaticSlideSoundStatic
            questionMediaPromiseResolve={this.questionMediaPromiseResolve}
            sound={media}
            slideWidth={slideWidth}
            slideTemplate={question.layout}
            showWaveform // should come from question
            UIInverseScale={UIInverseScale}
            questionChoiceCount={choiceCount}
            hideSoundTitles={this.props.hideSoundTitles}
          />
        </div>
        )}

        {bodyMediaType === 'video' && mediaIsPlayable && !mediaIsZoomable && (
          <div className='slide-mediaContainer slide-mediaContainer--video'>
            { isYoutubeAllowed ? (
              <StaticSlideVideoPlayer
                slideWidth={slideWidth}
                slideTemplate={question.layout}
                questionMediaDynamicHeight={questionMediaDynamicHeight}
                video={question.media}
                start={question.media.start}
                end={question.media.end}
                videoWidth={640}
                videoHeight={360}
              />
            ) : (
              <YoutubeBlocked blockedContentName='YouTube video' necessaryCookiesType='YouTube' />
            )}
          </div>
        )}

        {bodyMediaType === 'video' && mediaIsPlayable && mediaIsZoomable && (
        <div className='slide-mediaContainer slide-mediaContainer--video slide-mediaContainer--video--zoomable'>
          { isYoutubeAllowed ? (
            <StaticSlideVideoZoomablePlayer
              slideTemplate={question.layout}
              questionMediaDynamicHeight={questionMediaDynamicHeight}
              videoWidth={640}
              videoHeight={360}
            />
          ) : (
            <YoutubeBlocked blockedContentName='YouTube video' necessaryCookiesType='YouTube' />
          )}
        </div>
        )}

        {bodyMediaType === 'video' && !mediaIsPlayable && (
        <div className='slide-mediaContainer slide-mediaContainer--video'>
          {isYoutubeAllowed ? (
            <StaticSlideVideoStatic
              questionMediaPromiseResolve={this.questionMediaPromiseResolve}
              UIInverseScale={UIInverseScale}
              slideWidth={slideWidth}
              slideTemplate={question.layout}
              questionMediaDynamicHeight={questionMediaDynamicHeight}
              video={question.media}
            />
          ) : (
            <YoutubeBlocked
              additionalClasses='slide-mediaContainer slide-mediaContainer--video'
              blockedContentName='YouTube video'
              necessaryCookiesType='YouTube'
            />
          )}
        </div>
        )}

        {/* CHOICES */}
        <div style={slideChoicesStyle} className={`slide-choices ${this.props.choicesGroupHovered ? ' slide-choices--delayHovered ' : ' slide-choices--notDelayHovered '}`} onMouseEnter={this.onMouseEnterChoicesGroup} onMouseLeave={this.onMouseLeaveChoicesGroup}>
          {question.choices.map((choice, key) => {
            let choiceMediaType
            if (isChoiceMedia) {
              if (choice.media && choice.media.type === 'video') {
                choiceMediaType = 'video'
              } else if (choice.media && choice.media.type === 'image') {
                choiceMediaType = 'image'
              } else if (choice.media && choice.media.type === 'animatedGif') {
                choiceMediaType = 'animatedGif'
              } else if (choice.media && choice.media.type === 'sound') {
                choiceMediaType = 'sound'
              }
            }

            let itemName = 'choiceA'
            let choiceLetter = 'A'
            if (key === 1) {
              itemName = 'choiceB'
              choiceLetter = 'B'
            } else if (key === 2) {
              itemName = 'choiceC'
              choiceLetter = 'C'
            } else if (key === 3) {
              itemName = 'choiceD'
              choiceLetter = 'D'
            }

            let graphPercentageWidth
            let responseCount = 0

            if (processedResponses) {
              let graphScaling = totalResponses
              if (nowPlayingView) {
                graphScaling = expectedResponses
              }
              graphPercentageWidth = (processedResponses[`responsesAnswer${choiceLetter}`].length / graphScaling) * 100
              responseCount = processedResponses[`responsesAnswer${choiceLetter}`].length
            }

            let displayGraph = false
            let displaySyncGraph = false
            if (showGraph) {
              displayGraph = true
              displaySyncGraph = true
            } else if (reportView) {
              displayGraph = true
            }
            const letterArray = ['A', 'B', 'C', 'D']
            const choicesPlaying = this.props.playbackItem !== 'promptMedia' && this.props.isPlaying
            const isPlayingChoice = this.props.playbackItem === itemName && this.props.isPlaying
            const liveViewAnotherChoicePlaying = choicesPlaying && !isPlayingChoice
            return (
              <div key={key} onMouseEnter={() => { this.onMouseEnterChoice(letterArray[key]) }} style={{ fontSize: `${choiceFontSize}px`, ...choiceStyle }} className={`slide-choice ${key === activeZoomedChoiceIndex ? ' slide-choice--isActiveChoiceMediaZoomed ' : 'slide-choice--isNotActiveChoiceMediaZoomed '}${choiceMediaZoomed ? 'slide-choice--choiceMediaZoomed' : ''}${key ? ` slide-choice--index--${key} ` : ''}${choice.correct ? ' slide-choice--correct ' : ' slide-choice--incorrect '}${isSurvey ? ' slide-choice--survey ' : ' slide-choice--graded '}${colorScheme ? ` slide-choice--${colorScheme} ` : ''}${isAskScreen ? ' slide-choice--ask' : ' slide-choice--notAsk '}${showCorrect ? ' slide-choice--showCorrect' : ' slide-choice--notShowCorrect'}${isReviewScreen ? ' slide-choice--review' : ' slide-choice--notReview'}${isScanScreen ? ' slide-choice--scan' : ' slide-choice--notScan'}${showResponseCounts ? ' slide-choice--showResponseCounts ' : ' slide-choice--notShowResponseCounts '}${displaySyncGraph ? ' slide-choice--displaySyncGraph ' : ' slide-choice--notDisplaySyncGraph '}${liveViewAnotherChoicePlaying ? ' slide-choice--liveViewAnotherChoicePlaying ' : ' '}`}>
                <StaticLetterBlock
                  index={key}
                  isCorrect={choice.correct}
                  isSurvey={isSurvey}
                  isReviewScreen={isReviewScreen}
                  reportView={reportView}
                  showCorrect={showCorrect}
                  alwaysShowLetterblock={alwaysShowLetterblock}
                />

                {/* CHOICE TEXT */}
                {!isChoiceMedia && (
                <div className='slide-choice-text'>
                  <div dangerouslySetInnerHTML={createMarkup(choice.bodyHtml)} className='slide-choice-content' />
                </div>
                )}

                {/* CHOICE MEDIA */}
                {isChoiceMedia && (
                <div className='slide-choice-content'>
                  {choiceMediaType === 'image' && !mediaIsZoomable && choice.media.deliveryUrl && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--image slide-choice-mediaContainer--image--static'>
                    <StaticSlideChoiceImage
                      choiceLetter={choiceLetter}
                      choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                      image={choice.media}
                      UIInverseScale={UIInverseScale}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'image' && mediaIsZoomable && !choice.media.imageFill && (
                  <div onClick={() => { this.props.zoomMedia(itemName) }} className='slide-choice-mediaContainer slide-choice-mediaContainer--image'>
                    <StaticSlideChoiceZoomableFitImage
                      choiceIndex={key}
                      image={choice.media}
                      mediaChoiceWidth={mediaChoiceWidth}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'image' && mediaIsZoomable && choice.media.imageFill && (
                  <div onClick={() => { this.props.zoomMedia(itemName) }} className='slide-choice-mediaContainer slide-choice-mediaContainer--image'>
                    <StaticSlideChoiceZoomableFillImage
                      choiceIndex={key}
                      mediaChoiceWidth={mediaChoiceWidth}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'animatedGif' && mediaIsZoomable && choice.media.fileId && (
                  <div onClick={() => { this.props.zoomMedia(itemName) }} className='slide-choice-mediaContainer slide-choice-mediaContainer--gif'>
                    <StaticSlideChoiceZoomableAnimatedGIF
                      choiceIndex={key}
                      mediaChoiceWidth={mediaChoiceWidth}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'animatedGif' && !mediaIsZoomable && choice.media.fileId && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--gif slide-choice-mediaContainer--gif--static'>
                    <StaticSlideChoiceAnimatedGIF
                      choiceLetter={choiceLetter}
                      choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                      gif={choice.media}
                      fullyStatic={!mediaIsPlayable}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'sound' && mediaIsPlayable && !mediaIsZoomable && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--sound'>
                    <StaticSlideChoiceSoundPlayer
                      sound={choice.media}
                      slideWidth={slideWidth}
                      questionChoiceCount={choiceCount}
                      hideSoundTitles={this.props.hideSoundTitles}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'sound' && mediaIsPlayable && mediaIsZoomable && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--sound slide-choice-mediaContainer--sound--zoomable'>
                    <StaticSlideChoiceSoundZoomablePlayer
                      choiceIndex={key}
                      mediaChoiceWidth={mediaChoiceWidth}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'sound' && !mediaIsPlayable && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--sound'>
                    <StaticSlideChoiceSoundStatic
                      sound={choice.media}
                      title={choice.media.title}
                      slideWidth={slideWidth}
                      questionChoiceCount={choiceCount}
                      choiceLetter={choiceLetter}
                      choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                      hideSoundTitles={this.props.hideSoundTitles}
                    />
                  </div>
                  )}
                  {choiceMediaType === 'video' && mediaIsPlayable && !mediaIsZoomable && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--video'>
                    {isYoutubeAllowed ? (
                      <StaticSlideChoiceVideoPlayer
                        choiceIndex={key}
                        slideWidth={slideWidth}
                        slidetemplate={slideTemplate}
                        video={choice.media}
                        start={choice.media.start}
                        end={choice.media.end}
                        videoWidth={640}
                        videoHeight={360}
                        questionChoiceCount={choiceCount}
                      />
                    ) : (
                      <YoutubeBlocked hideIcon blockedContentName='YouTube video' necessaryCookiesType='YouTube' />
                    )}
                  </div>
                  )}
                  {choiceMediaType === 'video' && mediaIsPlayable && mediaIsZoomable && (
                  <div className={`slide-choice-mediaContainer slide-choice-mediaContainer--video${choiceMediaZoomed ? ' slide-choice-mediaContainer--video--choicesAreZoomed ' : ' slide-choice-mediaContainer--video--choicesAreNotZoomed '}${(choiceMediaZoomed && activeZoomedChoiceIndex === key) ? ' slide-choice-mediaContainer--video--choiceIsZoomed ' : ' slide-choice-mediaContainer--video--choiceIsNotZoomed'}`}>
                    {isYoutubeAllowed ? (
                      <StaticSlideChoiceVideoZoomablePlayer
                        choiceIndex={key}
                        videoWidth={640}
                        videoHeight={360}
                        mediaChoiceWidth={mediaChoiceWidth}
                      />
                    ) : (
                      <YoutubeBlocked hideIcon blockedContentName='YouTube video' necessaryCookiesType='YouTube' />
                    )}
                  </div>
                  )}
                  {choiceMediaType === 'video' && !mediaIsPlayable && (
                  <div className='slide-choice-mediaContainer slide-choice-mediaContainer--video'>
                    {isYoutubeAllowed ? (
                      <StaticSlideChoiceVideoStatic
                        choiceIndex={key}
                        slideWidth={slideWidth}
                        slidetemplate={slideTemplate}
                        video={choice.media}
                        videoWidth={640}
                        videoHeight={360}
                        start={choice.media.start}
                        end={choice.media.end}
                        questionChoiceCount={choiceCount}
                        choiceLetter={choiceLetter}
                        choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                      />
                    ) : (
                      <YoutubeBlocked hideIcon blockedContentName='YouTube video' necessaryCookiesType='YouTube' />
                    )}
                  </div>
                  )}
                </div>
                )}
                {/* CHOICE GRAPH */}
                {displayGraph && (
                <div className='slide-choice-graph'>
                  <div style={{ width: `${graphPercentageWidth}%` }} className='slide-choice-graph-bar' />
                </div>
                )}

                {/* RESPONSE COUNT */}
                {displayGraph && showResponseCounts && (
                <div className={`slide-choice-responseCount ${responseCount === 0 ? 'slide-choice-responseCount--isZero' : ''}`}>
                  {responseCount}
                </div>
                )}
              </div>
            )
          })}
        </div>
        {!question.measurements && ( // font size calculator as fallback if questoin doesn't have measurements
        <div className='editorGhostElementsContainer'>
          <FontSizeCalculatorComponent
            question={question}
            updatePerfectCombo={(perfectCombo) => { this.updateQuestionFontSizes(perfectCombo) }}
            updateQuestionMediaDynamicHeight={(questionMediaDynamicHeight) => { this.updateQuestionMediaDynamicHeight(questionMediaDynamicHeight) }}
            mediaRatio='MM'
          />
        </div>
        )}
      </div>
    )
  }
}

RichStaticSlide.defaultProps = {
  viewType: 'preview',
}

export default RichStaticSlide
