import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import includes from 'lodash/includes'
import EditorIcon from '../../misc/EditorIcons'
import { showEditVideoModal } from '../utils/showEditVideoModal'
import { deleteQuestionVideo } from '../../../prosemirror/utils/editorActions/deleteQuestionVideo'
import { deleteQuestionImage } from '../../../prosemirror/utils/editorActions/deleteQuestionImage'
import { deleteQuestionSound } from '../../../prosemirror/utils/editorActions/deleteQuestionSound'
import { deleteQuestionGIF } from '../../../prosemirror/utils/editorActions/deleteQuestionGIF'
import { showInsertVideoModal } from '../utils/showInsertVideoModal'
import { showEditImageModal } from '../utils/showEditImageModal'
import { showInsertImageModal } from '../utils/showInsertImageModal'
import { showInsertGIFModal } from '../utils/showInsertGIFModal'
import { showEditSoundModal } from '../utils/showEditSoundModal'
import { showInsertSoundModal } from '../utils/showInsertSoundModal'
import tumblePodcastIds from '../../../staticData/tumblePodcastIds'
import { isContentAllowed } from '../../../utils/cookieHelpers'
import { ContentBlockedSimpleNotification, YoutubeBlocked } from '../../misc/ContentBlocked'

const FALLBACK_IMAGE = 'resources/fallback_image'
const FALLBACK_GIF = 'resources/fallback_gif'

class EditorCanvasEditMediaBarButton extends React.Component {
  render() {
    return (
      <button className='editor-canvas-editMediaBar-btn' onClick={this.props.onClick} disabled={this.props.disabled}>
        <div className='editor-canvas-editMediaBar-btn-iconContainer'>
          <EditorIcon name={this.props.iconName} />
        </div>
        <div className='editor-canvas-editMediaBar-btn-label'>
          {this.props.label}
        </div>
        <div className='editor-canvas-editMediaBar-btn-bottomBorder' />
        <div className='editor-canvas-editMediaBar-btn-topHoverBorder' />
        <div className='editor-canvas-editMediaBar-btn-bottomHoverBorder' />
      </button>
    )
  }
}

class EditorCanvasEditMediaBarRemoveButton extends React.Component {
  render() {
    const { onClick } = this.props
    return (
      <button className='editor-canvas-editMediaBar-removeBtn' onClick={onClick} disabled={this.props.disabled}>
        Remove
      </button>
    )
  }
}

class EditorCanvasEditMediaBar extends React.Component {
  constructor() {
    super()
    this.showEditVideoModal = this.showEditVideoModal.bind(this)
    this.showReplaceVideoModal = this.showReplaceVideoModal.bind(this)
    this.showEditImageModal = this.showEditImageModal.bind(this)
    this.showEditSoundModal = this.showEditSoundModal.bind(this)
    this.copyImageToClipboard = this.copyImageToClipboard.bind(this)
    this.state = {
      isYoutubeAllowed: true,
    }
  }

  componentDidMount() {
    const isYoutubeAllowed = isContentAllowed('youtube')
    this.setState({ isYoutubeAllowed })
  }

  // TODO some duplication here- could combine show modal functions
  showEditVideoModal() {
    let videoNode
    let videoNodePos
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionVideo') {
            videoNode = questionNode
            videoNodePos = questionPos
          }
        })
      }
    })
    const isInsertFlow = false
    const isQuestion = true
    const autoplay = false
    const searchState = null
    showEditVideoModal(isInsertFlow, searchState, autoplay, this.props.media, null, isQuestion, null, null, videoNode, videoNodePos)
  }

  showReplaceVideoModal() {
    let insertPos
    let questionPos
    let questionNode
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        questionPos = pos
        questionNode = node
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionVideo') {
            insertPos = {
              start: questionPos,
              end: questionPos + questionNode.nodeSize,
            }
          }
        })
      }
    })
    const isQuestion = true
    const searchState = null
    showInsertVideoModal(searchState, insertPos, isQuestion, questionPos, questionNode)
  }

  showEditImageModal() {
    let imageNode
    let imageNodePos

    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionImage') {
            imageNode = questionNode
            imageNodePos = questionPos
          }
        })
      }
    })
    const { fileId } = this.props.media
    showEditImageModal(fileId, imageNode, imageNodePos)
  }

  showReplaceImageModal() {
    let insertPos
    let questionPos
    let questionNode
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        questionPos = pos
        questionNode = node
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionImage') {
            // insertPos=questionPos+questionNode.nodeSize
            insertPos = {
              start: questionPos,
              end: questionPos + questionNode.nodeSize,
            }
          }
        })
      }
    })
    const onCloseModal = null
    const isQuestion = true
    showInsertImageModal(insertPos, onCloseModal, isQuestion, questionPos, questionNode)
  }

  showReplaceGIFModal() {
    let insertPos
    let questionPos
    let questionNode
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        questionPos = pos
        questionNode = node
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionAnimatedGIF') {
            // insertPos=questionPos+questionNode.nodeSize
            insertPos = {
              start: questionPos,
              end: questionPos + questionNode.nodeSize,
            }
          }
        })
      }
    })

    const isQuestion = true
    showInsertGIFModal(insertPos, isQuestion, questionPos, questionNode)
  }

  showEditSoundModal(focusTitle) {
    const {
      fileId, title, attribution, fullDuration, start, end,
    } = this.props.media
    let soundNode
    let soundNodePos
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionSound') {
            soundNode = questionNode
            soundNodePos = questionPos
          }
        })
      }
    })
    showEditSoundModal(fileId, title, attribution, fullDuration, start, end, soundNode, soundNodePos, focusTitle)
  }

  showReplaceSoundModal() {
    let insertPos
    let questionPos
    let questionNode
    window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
      if (node.attrs.activeslide && node.attrs.activeslide === true) {
        questionPos = pos
        questionNode = node
        window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
          if (questionNode.type.name === 'questionSound') {
            insertPos = {
              start: questionPos,
              end: questionPos + questionNode.nodeSize,
            }
          }
        })
      }
    })

    const isQuestion = true
    showInsertSoundModal(insertPos, isQuestion, questionPos, questionNode)
  }

  copyImageToClipboard() {
    window.getSelection().removeAllRanges()
    const imageElem = this.imageRef
    const range = document.createRange()
    range.selectNode(imageElem)
    window.getSelection().addRange(range)
    try {
      // Now that we've selected the anchor text, execute the copy command
      // var successful = document.execCommand('copy');
      this.props.showNotification('You can paste it into a question, choice, or outside Plickers.', 'Copied image to your clipboard:', 'default')
    } catch (err) {
      // no op
    }
    window.getSelection().removeAllRanges()
  }

  render() {
    const {
      mediaType, media, isViewOnly, itemIsLockedByAnotherUser,
    } = this.props

    const { isYoutubeAllowed } = this.state

    let videoURL
    if (media.type === 'video') {
      const prettifiedVideoURL = `youtube.com/watch?v=${media.youtubeId}`
      videoURL = `https://${prettifiedVideoURL}`
    }

    let giphyURL
    if (media.type === 'animatedGif') {
      giphyURL = `http://giphy.com/gifs/${media.giphySlug}`
    }
    let imageUploaded = false
    let gifUploaded = false

    if (media.fileId && media.type === 'image' && media.fileId !== FALLBACK_IMAGE) {
      imageUploaded = true
    }

    if (media.fileId && media.type === 'animatedGif' && media.fileId !== FALLBACK_GIF) {
      gifUploaded = true
    }
    let isTumblePodcast = false
    if (media.fileId && media.type === 'sound') {
      isTumblePodcast = includes(tumblePodcastIds, media.fileId)
    }

    return (
      <React.Fragment>
        {mediaType === 'video' && (
        <div className={`editor-canvas-editMediaBar${isViewOnly ? ' editor-canvas-editMediaBar--viewOnly ' : ''}${itemIsLockedByAnotherUser ? ' editor-canvas-editMediaBar--itemIsLockedByAnotherUser ' : ''}`}>
          <div className='editor-canvas-editMediaBar-header'>
            Video
          </div>
            {!isViewOnly && isYoutubeAllowed && (
            <React.Fragment>
              <EditorCanvasEditMediaBarButton iconName='editMediaTrim' label='Edit Video' onClick={this.showEditVideoModal} disabled={itemIsLockedByAnotherUser} />
              <EditorCanvasEditMediaBarButton iconName='replaceMedia' label='Replace' onClick={this.showReplaceVideoModal} disabled={itemIsLockedByAnotherUser} />
            </React.Fragment>
            )}
            {isYoutubeAllowed && (
            <React.Fragment>
              <CopyToClipboard text={videoURL} options={{ format: 'text/plain' }} onCopy={() => { this.props.showNotification('Paste it into insert video to add it to a question.', 'Copied YouTube link to your clipboard: ', 'default') }}>
                <EditorCanvasEditMediaBarButton iconName='copyMedia' label='Copy YouTube Link' />
              </CopyToClipboard>

              <a target='_blank' href={videoURL} rel='noopener noreferrer'>
                <EditorCanvasEditMediaBarButton iconName='openLink' label='Open in YouTube' />
              </a>
            </React.Fragment>
            )}
            {!isViewOnly && isYoutubeAllowed &&
            <EditorCanvasEditMediaBarRemoveButton onClick={deleteQuestionVideo} disabled={itemIsLockedByAnotherUser} />}
            {!isYoutubeAllowed && (
              <YoutubeBlocked
                blockedContentName='YouTube'
                necessaryCookiesType='functional'
              />
            )}
        </div>
        )}

        {mediaType === 'image' && (
        <div className={`editor-canvas-editMediaBar ${imageUploaded ? '' : 'editor-canvas-editMediaBar--disabled'}${isViewOnly ? ' editor-canvas-editMediaBar--viewOnly ' : ''}${itemIsLockedByAnotherUser ? ' editor-canvas-editMediaBar--itemIsLockedByAnotherUser ' : ''}`}>
          <div className='editor-canvas-editMediaBar-header'>
            Image
          </div>

          {/* MAYBE PUT BACK IN
            {imagePlaceholder &&
              <div className='editor-canvas-editMediaBar--loadingSpinnerContainer'>
                <div className='editor-canvas-editMediaBar--loadingSpinner'/>
              </div>
            }
            */}

          {!isViewOnly && (
          <React.Fragment>
            <EditorCanvasEditMediaBarButton iconName='editImage' label='Edit Image' disabled={!imageUploaded || itemIsLockedByAnotherUser} onClick={this.showEditImageModal} />
            <EditorCanvasEditMediaBarButton iconName='replaceMedia' label='Replace' disabled={!imageUploaded || itemIsLockedByAnotherUser} onClick={this.showReplaceImageModal} />
          </React.Fragment>
          )}

          <EditorCanvasEditMediaBarButton iconName='copyMedia' label='Copy Image' disabled={!imageUploaded} onClick={this.copyImageToClipboard} />

          <a target='_blank' href={`${media.deliveryUrl}`} disabled={!imageUploaded} download rel='noopener noreferrer'>
            <EditorCanvasEditMediaBarButton iconName='downloadMedia' disabled={!imageUploaded} label='Download Image' />
          </a>

          {!isViewOnly &&
          <EditorCanvasEditMediaBarRemoveButton onClick={deleteQuestionImage} disabled={!imageUploaded || itemIsLockedByAnotherUser} />}
          <img className='editor-canvas-editMediaBar-imageToCopy' ref={(c) => { this.imageRef = c }} src={this.props.media.deliveryUrl} alt='' />
        </div>
        )}

        {mediaType === 'animatedGif' && (
        <div className={`editor-canvas-editMediaBar ${gifUploaded ? '' : 'editor-canvas-editMediaBar--disabled'}${isViewOnly ? ' editor-canvas-editMediaBar--viewOnly ' : ''}${itemIsLockedByAnotherUser ? ' editor-canvas-editMediaBar--itemIsLockedByAnotherUser ' : ''}`}>
          <div className='editor-canvas-editMediaBar-header'>
            Animated GIF
          </div>

          {!isViewOnly &&
          <EditorCanvasEditMediaBarButton iconName='replaceMedia' label='Replace' onClick={this.showReplaceGIFModal} disabled={!gifUploaded || itemIsLockedByAnotherUser} />}

          {media.host === 'giphy' && (
          <CopyToClipboard text={giphyURL} options={{ format: 'text/plain' }} onCopy={() => { this.props.showNotification('Paste it into insert GIF to add it to a question.', 'Copied GIPHY link to your clipboard: ', 'default') }}>
            <EditorCanvasEditMediaBarButton iconName='copyMedia' label='Copy GIPHY Link' />
          </CopyToClipboard>
          )}

          {media.host === 'giphy' && (
          <a target='_blank' disabled={!gifUploaded} href={giphyURL} rel='noopener noreferrer'>
            <EditorCanvasEditMediaBarButton iconName='openLink' label='View on GIPHY' />
          </a>
          )}

          {/* IF CLOUDINARY */}
          {media.host === 'cloudinary' && (
          <a target='_blank' href={`${media.gifUrl}`} disabled={!gifUploaded} download rel='noopener noreferrer'>
            <EditorCanvasEditMediaBarButton iconName='downloadMedia' label='Download GIF' />
          </a>
          )}

          {!isViewOnly &&
          <EditorCanvasEditMediaBarRemoveButton disabled={!gifUploaded || itemIsLockedByAnotherUser} onClick={deleteQuestionGIF} />}

        </div>
        )}

        {mediaType === 'sound' && (
        <div className={`editor-canvas-editMediaBar ${isViewOnly ? ' editor-canvas-editMediaBar--viewOnly ' : ''}${itemIsLockedByAnotherUser ? ' editor-canvas-editMediaBar--itemIsLockedByAnotherUser ' : ''}`}>
          <div className='editor-canvas-editMediaBar-header'>
            Sound Clip
          </div>

          {!isViewOnly && (
          <React.Fragment>
            <EditorCanvasEditMediaBarButton iconName='editMediaTrim' label='Edit Sound Clip' onClick={() => { this.showEditSoundModal(false) }} disabled={itemIsLockedByAnotherUser} />
            <EditorCanvasEditMediaBarButton iconName='renameMedia' label='Rename' onClick={() => { this.showEditSoundModal(true) }} disabled={itemIsLockedByAnotherUser} />
            <EditorCanvasEditMediaBarButton iconName='replaceMedia' label='Replace' onClick={this.showReplaceSoundModal} disabled={itemIsLockedByAnotherUser} />
          </React.Fragment>
          )}

          {!isTumblePodcast && (
          <a target='_blank' href={`${media.deliveryUrl}.mp3`} download rel='noopener noreferrer'>
            <EditorCanvasEditMediaBarButton iconName='downloadMedia' label='Download MP3' />
          </a>
          )}

          {!isViewOnly &&
          <EditorCanvasEditMediaBarRemoveButton onClick={deleteQuestionSound} disabled={itemIsLockedByAnotherUser} />}

        </div>
        )}

      </React.Fragment>
    )
  }
}

export default EditorCanvasEditMediaBar
