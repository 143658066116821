import filter from 'lodash/filter'

export function calculateDataForReportPack(polls,excludedPolls) {	
	const filteredPolls=filter(polls, function(poll) {
		if(poll.setObject){
			if(!excludedPolls.setPolls[poll.id]){
				return poll
			}
		}else{
			if(!excludedPolls.questionPolls[poll.id]){
				return poll
			}
		}
	})
 
	filteredPolls.map((poll)=>{
		if(poll.setObject){
			let filteredSetPollPolls={}
			Object.keys(poll.polls).map((key)=>{
				if(!excludedPolls.setPollPolls[`${poll.id}-${key}`]){
					filteredSetPollPolls[key]=poll.polls[key]
				}	
				return null
			})
			poll.filteredPolls=filteredSetPollPolls
		}
		return null
	})

	return filteredPolls
}