import React from 'react'
import ScorePill from '../uiKit/ScorePill'


class ScoreSheetResponseRows extends React.Component{
	render(){
		const {historyPolls, collapsedView, students,guests,excludedPolls} = this.props
		return(
			<React.Fragment>					
				{historyPolls.map((poll,index) => {
					if(poll.setObject){ //is a setPoll
						if(!collapsedView){
						return(
							<ScoreSheetSetResponseColumnGroup excludedPolls={excludedPolls} key={index} students={students} guests={guests} setPoll={poll} />
						)
					 }else{
							let someSetPollsExcluded=false
							Object.keys(excludedPolls.setPollPolls).map((key)=>{
								if(key.includes(poll.id)){
									someSetPollsExcluded=true
								}
								return null
							})
							return(
								<ScoreSheetCollapsedColumn someSetPollsExcluded={someSetPollsExcluded }excludedPolls={excludedPolls} poll={poll} key={poll.id} students={students} guests={guests} />
							)
					 }
					}else{
						if(!collapsedView){
						const isExcluded=excludedPolls.questionPolls[poll.id]
						return(
							<ScoreSheetResponseColumn isExcluded={isExcluded} key={poll.id} poll={poll} students={students} guests={guests}  />
						)
					 }else return <ScoreSheetCollapsedColumn excludedPolls={excludedPolls} poll={poll} key={index} students={students} guests={guests} />
					} 					 	 	
				})}
			</React.Fragment>
		)
	}
}

export default ScoreSheetResponseRows



class ScoreSheetSetResponseColumnGroup extends React.Component{

	render(){
		const {setPoll, students,guests,excludedPolls} = this.props
		return(
			<div className="scoreSheet-table-responseRows-setResponseColumnGroup">							
				{Object.keys(setPoll.polls).map((key,index) => {
					const poll=setPoll.polls[key]
					const isExcluded=excludedPolls.setPollPolls[`${setPoll.id}-${key}`]
					return(						 	 	
						<ScoreSheetResponseColumn isExcluded={isExcluded} key={`${setPoll.id}-${key}`} students={students} guests={guests} poll={poll} setPoll={setPoll}/>            				
					)
				})}				
			</div>
		)
	}
}

class ScoreSheetResponseCell extends React.Component{

	render(){
		const {response}=this.props
		let answer='-'
		let type='absent'
		if(response && response.isValid){
			answer=response.answer
			if(response.isSurvey){
				type='survey'
			}else{
				type=response.isCorrect===true?'correct':'incorrect'
			}
		}
		return(
			<div className={'scoreSheet-table-responseRows-responseCell ' + type }>
				<div className="scoreSheet-table-responseRows-responseCell-response">
					{answer}
				</div>
			</div>
		)
	}
}

class ScoreSheetCollapsedCell extends React.Component{

	render(){
		const {isSet, score,isExcluded,totalScoreExcludingExcluded,someSetPollsExcluded} = this.props
		return(
			<div className={'scoreSheet-table-responseRows-collapsedCell ' +
				(isSet ? 'scoreSheet-table-responseRows-collapsedCell--set' : 'scoreSheet-table-responseRows-collapsedCell--question') +
				(isExcluded ? ' scoreSheet-table-responseRows-collapsedCell--isExcluded ' : '') +
				(someSetPollsExcluded ? ' scoreSheet-table-responseRows-collapsedCell--isSomeSetPollsExcluded ' : '')
			}>					
				{score !=='-' &&
					<span className='scoreSheet-table-responseRows-collapsedCell-primary'>
						<ScorePill disabled={isExcluded} score={score} typeDot />
					</span>
				}
				{score !=='-' && someSetPollsExcluded && !isExcluded &&
					<span className='scoreSheet-table-responseRows-collapsedCell-secondary'>
						<ScorePill disabled={isExcluded} score={totalScoreExcludingExcluded} typeDot />					
					</span>
				}				

				{score === '-' &&
					<span className='scoreSheet-table-responseRows-collapsedCell--absent'>Absent</span>
				}
			</div>
		)
	}
}

class ScoreSheetResponseColumn extends React.Component{

	render(){
		const {students,guests, poll,isExcluded} = this.props
		return(	
			<div className={'scoreSheet-table-responseRows-responseColumn ' + (isExcluded ? 'scoreSheet-table-responseRows-responseColumn--isExcluded' : '')}>					
				{students.map((student,index) => {
					const response=poll.responsesByStudent[student.id]
					return(						 	 	
						<ScoreSheetResponseCell key={`${student.id}_${poll.id}_${poll.maxCaptured}`} response={response}/>			
					)
				})}

				{guests.map((guest,index) => {
					const response=poll.responsesByGuest[guest]
					return(						 	 	
						 <ScoreSheetResponseCell key={`${guest}_${poll.id}_${poll.maxCaptured}`}  response={response}/>			
					)
				})}
			</div>
		)
	}
}


class ScoreSheetCollapsedColumn extends React.Component{

	render(){
		const {poll,students,guests,excludedPolls} = this.props
		let isExcluded=false
		if(poll.setObject){
			if(excludedPolls.setPolls[poll.id]){
				isExcluded=true
			}
		}else{
			if(excludedPolls.questionPolls[poll.id]){
				isExcluded=true
			}
		}
		return(			
			<div className="scoreSheet-table-responseRows-collapsedColumn">										
				{students.map((student,index) => {	
					let score='-'
					let isSet=false
					let totalScoreExcludingExcluded=0
					if(poll.setObject){
						isSet=true
						let totalCorrect=0
						let totalResponses=0
						let totalSurveyResponses=0
						Object.keys(poll.polls).map((key)=>{
							const setPollPoll=poll.polls[key]
							const isExcluded=excludedPolls.setPollPolls[`${poll.id}-${setPollPoll.id}`]
							if(!isExcluded){
								const response=setPollPoll.responsesByStudent[student.id]
								if(response && response.isValid){
									if(!response.isSurvey){
										totalResponses+=1
									}
									if(response.isSurvey){
										totalSurveyResponses+=1
									}else if(response.isCorrect){
										totalCorrect+=1
									}
								}
							}
							return null	
						})					
						if(totalResponses !==0){
							totalScoreExcludingExcluded=100*totalCorrect/totalResponses
						}else if(totalResponses===0 && totalSurveyResponses>0){
							totalScoreExcludingExcluded=-1
						}
						if(poll.aggregatedResponsesByStudent[student.id]){
							const totalAggregatedResponses=poll.aggregatedResponsesByStudent[student.id].totalResponses
							 if(totalAggregatedResponses !==0){
								score=100 * poll.aggregatedResponsesByStudent[student.id].totalCorrect/totalAggregatedResponses
							}
							else if(totalAggregatedResponses===0 && poll.aggregatedResponsesByStudent[student.id].totalSurveyResponses>0){
								score=-1
							}
						}						
					}else{
						isSet=false
						if(poll.responsesByStudent[student.id]){
							if(poll.responsesByStudent[student.id].isCorrect){
								score=100
							}else if(poll.responsesByStudent[student.id].isSurvey){
								score=-1
							}else{
								score=0
							}
						}
					}
					return(						 	 	
						<ScoreSheetCollapsedCell someSetPollsExcluded={this.props.someSetPollsExcluded} totalScoreExcludingExcluded={totalScoreExcludingExcluded} isExcluded={isExcluded} isSet={isSet} key={student.id} score={score}/>			
							)
					})}				

				{guests.map((guest,index) => {	
					let score='-'
					let isSet=false
					let totalScoreExcludingExcluded=0

					if(poll.polls){
						isSet=true
						let totalCorrect=0
						let totalResponses=0
						let totalSurveyResponses=0
						Object.keys(poll.polls).map((key)=>{
							const setPollPoll=poll.polls[key]
							const isExcluded=excludedPolls.setPollPolls[`${poll.id}-${setPollPoll.id}`]
							if(!isExcluded && setPollPoll.responsesByGuest){
								const response=setPollPoll.responsesByGuest[guest]
								if(response && response.isValid){
									if(!response.isSurvey){		
										totalResponses+=1
									}
									if(response.isSurvey){
										totalSurveyResponses+=1
									}else if(response.isCorrect){
										totalCorrect+=1
									}
								}
							}
							return null	
						})
						if(totalResponses!==0){						
							totalScoreExcludingExcluded=100*totalCorrect/totalResponses
						}else if(totalResponses===0 && totalSurveyResponses>0){						
							totalScoreExcludingExcluded=-1
						}

						if(poll.aggregatedResponsesByGuest[guest]){
							const totalAggregatedResponses=poll.aggregatedResponsesByGuest[guest].totalResponses						
							 if(totalAggregatedResponses!==0){
								score=100 * poll.aggregatedResponsesByGuest[guest].totalCorrect/totalAggregatedResponses
							}
							else if(totalAggregatedResponses===0 && poll.aggregatedResponsesByGuest[guest].totalSurveyResponses>0){
								score=-1
							}
						}						
					}else{
						isSet=false
						if(poll.responsesByGuest[guest]){
							if(poll.responsesByGuest[guest].isCorrect){
								score=100
							}else if(poll.responsesByGuest[guest].isSurvey){
								score=-1
							}else{
								score=0
							}
						}
					}
					return(						 	 	
						<ScoreSheetCollapsedCell someSetPollsExcluded={this.props.someSetPollsExcluded}  totalScoreExcludingExcluded={totalScoreExcludingExcluded} isExcluded={isExcluded} isSet={isSet} key={guest} score={score}/>			
							)
					})}								
			</div>
		)
	}
}
