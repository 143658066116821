import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import SideNav from '../../components/navigation/SideNav'
import {
  showNewSectionModal, showManageSectionsModal, showNewRepoModal, showEditRepoModal, hideModal,
} from '../../actions/modals'
import { newRepo } from '../../actions/repos'
import { createPoll } from '../../utils/createPoll'
import { showNotification } from '../../actions/notifications'
import { moveToRepo } from '../../utils/moveToRepo'

const navBarIsVisible = (location) => {
  if ( // don't show nav bar for the folowing routes
    !(location.pathname.indexOf('/seteditor/') > -1) &&
    !(location.pathname.indexOf('/editor/') > -1) &&
    !(location.pathname.indexOf('/liveview') > -1) &&
    !(location.pathname.indexOf('/scoresheet') > -1) &&
    !(location.pathname.indexOf('/classRoster/') > -1) &&
    !(location.pathname.indexOf('/integration/') > -1) &&
    !(location.pathname === '/classRoster/') &&
    !(location.pathname.indexOf('/confirm-email') > -1) &&
    !(location.pathname.indexOf('/dnd') > -1) &&
    !(location.pathname.indexOf('/scan-helper') > -1) &&
    !(location.pathname.indexOf('/import-questions-helper') > -1) &&
    !(location.pathname.indexOf('/packreview') > -1) &&
    !(location.pathname.indexOf('/creatorslist') > -1) &&
    !(location.pathname.indexOf('/rich') > -1) &&
    !(location.pathname.indexOf('/static') > -1) &&
    !(location.pathname.indexOf('/dev/fs') > -1) &&
    !(location.pathname.indexOf('/dev/spring') > -1) &&
    !(location.pathname.indexOf('/dev/zoomtest') > -1) &&
    !(location.pathname.indexOf('/slideservice') > -1) &&
    !(location.pathname.indexOf('/migration-service') > -1) &&
    !(location.pathname.indexOf('/setup-autoplay') > -1) &&
    !(location.pathname.indexOf('/reset-password') > -1) &&
    !(location.pathname.indexOf('/delete-account') > -1) &&
    !(location.pathname.indexOf('/whatsnew') > -1) &&
    !(location.pathname.indexOf('/explorer') > -1)
  ) return true
  return false
}

class SideNavContainer extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      (nextProps.location.pathname !== this.props.location.pathname) ||
      (nextProps.sections !== this.props.sections) ||
      (nextProps.repos !== this.props.repos)
    ) {
      return true
    } return null
  }

  render() {
    if (navBarIsVisible(this.props.history.location)) {
      return (
        <React.Fragment>
          <SideNav
            sections={this.props.sections}
            showNewSectionModal={this.props.showNewSectionModal}
            showManageSectionsModal={this.props.showManageSectionsModal}
            newRepo={this.props.showNewRepoModal}
            repos={this.props.repos}
            createPoll={this.props.createPoll}
            showNotification={this.props.showNotification}
            moveToRepo={this.props.moveToRepo}
            showEditRepoModal={this.props.showEditRepoModal}
            hideModal={this.props.hideModal}
            meta={this.props.meta}
          />
        </React.Fragment>
      )
    } return null
  }
}

export default withRouter(connect(
  (state) => ({
    meta: state.meta,
    sections: state.sections,
    repos: sortBy(filter(state.repos, (repo) => {
      if (!repo.published || (repo.published && (repo.role === 'author' || repo.following))) {
        return repo
      }
    }),
    [(repo) => repo.name.toLowerCase()]),

  }),
  {
    showNewSectionModal,
    showManageSectionsModal,
    newRepo,
    createPoll,
    showNotification,
    showNewRepoModal,
    showEditRepoModal,
    moveToRepo,
    hideModal,
  },
)(SideNavContainer))
