import filter from 'lodash/filter'
import moment from 'moment'
import { sortStudentList } from '../sortStudentList'
import { sortGuestsList } from '../sortGuestsList'

function getCorrectAnswerForQuestion(question) {
  const correctAnswers = []
  const lettersArray = ['A', 'B', 'C', 'D']
  question.choices.map((choice, index) => {
    if (choice.correct) {
      correctAnswers.push(lettersArray[index])
    }
    return null
  })
  let correctAnswersString
  if (correctAnswers.length === 0) {
    correctAnswersString = 'Survey'
  } else {
    correctAnswersString = correctAnswers.join()
  }
  return correctAnswersString
}

// TODO Clean this up when final data format decided

export function calculateDataForCsvExport(polls, section, studentsWithTotals, guestsWithTotals, dateRange, excludedPolls, sortedBy, sortOrder) {
  const csvData = []
  let { endDate } = dateRange
  if (moment().diff(endDate, 'days') < 0) { // if end date is in the future (part way through granularity), use today
    endDate = moment()
  }

  const titleRow = [`${section.name} ${moment(dateRange.startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`]
  // header row 1
  const headerRow1 = ['Card Number', 'First name', 'Last Name', 'Score', 'Correct', 'Answered'] // main headers, question bodys
  const headerRow2 = ['', '', '', '', '', '']// set names (unless independent question)
  const headerRow3 = ['', '', '', '', '', '']// links to reports
  const headerRow4 = ['', '', '', '', '', '']// the correct response for the question

  const filteredPolls = filter(polls, (poll) => { // do not include excluded polls in cvs export
    if (poll.setObject) {
      if (!excludedPolls[section.id].setPolls[poll.id]) {
        return poll
      }
    } else if (!excludedPolls[section.id].questionPolls[poll.id]) {
      return poll
    }
  })

  filteredPolls.map((poll) => {
    if (poll.setObject) {
      const filteredSetPollPolls = {}
      Object.keys(poll.polls).map((key) => {
        if (!excludedPolls[section.id].setPollPolls[`${poll.id}-${key}`]) {
          filteredSetPollPolls[key] = poll.polls[key]
        }
        return null
      })
      poll.filteredPollPolls = filteredSetPollPolls
    }
    return null
  })

  const linkStem = process.env.REACT_APP_URL
  filteredPolls.map((poll) => {
    if (poll.polls) {
      Object.keys(poll.filteredPollPolls).map((key) => { // loop through setPollPolls
        const setPollPoll = poll.filteredPollPolls[key]
        let headerTitle = ''
        let correctAnswer = ''
        const link = `${linkStem}/setreport/${poll.id}/${setPollPoll.question}`
        if (setPollPoll.snapshot) {
          headerTitle = setPollPoll.snapshot.body
          correctAnswer = getCorrectAnswerForQuestion(setPollPoll.snapshot)
        }
        headerRow1.push(headerTitle)
        headerRow3.push(link)
        headerRow4.push(correctAnswer)
        if (poll.snapshot) {
          headerRow2.push(poll.snapshot.name)
        } else headerRow2.push('')
        return null
      })
    } else {
      let headerTitle = ''
      let correctAnswer = ''
      const link = `${linkStem}/questionreport/${poll.id}`
      if (poll.snapshot) {
        headerTitle = poll.snapshot.body
        correctAnswer = getCorrectAnswerForQuestion(poll.snapshot)
      }
      headerRow1.push(headerTitle)
      headerRow2.push('')
      headerRow3.push(link)
      headerRow4.push(correctAnswer)
    }
    return null
  })
  csvData.push(titleRow)
  csvData.push([])
  csvData.push(headerRow1)
  csvData.push(headerRow2)
  csvData.push(headerRow3)
  csvData.push(headerRow4)

  let students = [] // TODO combine students and guests
  if (section) {
    students = sortStudentList(section.students, studentsWithTotals, sortedBy, sortOrder)
  }

  students.map((student) => {
    // ['Student name','total %','Correct','Answered']
    const studentTotals = studentsWithTotals[student.id]
    let score = '-'
    if (studentTotals.totalResponses) {
      score = `${Math.round(100 * (studentTotals.totalCorrect / studentTotals.totalResponses))}%`
    }
    let cardNumber = student.card
    if (cardNumber === -1) {
      cardNumber = 'Archived'
    }

    const studentRow = [cardNumber, `${student.firstName}`, `${student.lastName}`, score, studentTotals.totalCorrect, studentTotals.totalResponses]

    filteredPolls.map((poll) => {
      if (poll.polls) {
        Object.keys(poll.filteredPollPolls).map((key) => {
          const setPollPoll = poll.filteredPollPolls[key]
          let answer = '-'
          const response = setPollPoll.responsesByStudent[student.id]
          if (response && response.isValid) {
            answer = response.answer
          }
          studentRow.push(answer)
          return null
        })
      } else {
        let answer = '-'
        const response = poll.responsesByStudent[student.id]
        if (response && response.isValid) {
          answer = response.answer
        }
        studentRow.push(answer)
      }
      return null
    })
    csvData.push(studentRow)
    return null
  })

  let guests = []
  if (guestsWithTotals) {
    const guestsIncTotal = Object.keys(guestsWithTotals)
    guests = guestsIncTotal.slice(0, guestsIncTotal.length - 1) // temp to remove total key
    guests = sortGuestsList(guests, guestsWithTotals, sortedBy, sortOrder)
  }

  guests.map((guest) => {
    // ['Student name','total %','Correct','Answered']
    const guestTotal = guestsWithTotals[guest]
    let score = '-'
    if (guestTotal.totalResponses) {
      score = `${Math.round(100 * (guestTotal.totalCorrect / guestTotal.totalResponses))}%`
    }

    const card = guest.slice(6)

    const guestRow = [card, guest, '', score, guestTotal.totalCorrect, guestTotal.totalResponses]

    filteredPolls.map((poll) => {
      if (poll.polls) {
        Object.keys(poll.filteredPollPolls).map((key) => {
          const setPollPoll = poll.filteredPollPolls[key]
          let answer = '-'
          const response = setPollPoll.responsesByGuest[guest]
          if (response && response.isValid) {
            answer = response.answer
          }
          guestRow.push(answer)
          return null
        })
      } else {
        let answer = '-'
        const response = poll.responsesByGuest[guest]
        if (response && response.isValid) {
          answer = response.answer
        }
        guestRow.push(answer)
      }
      return null
    })
    csvData.push(guestRow)
    return null
  })

  return csvData
}
