import React from 'react'
import { connect } from 'react-redux'
import { addQuestionImage, addChoiceImage } from '../../prosemirror/utils/editorActions/addImage'
import EditorInsertImageModal from '../../components/richEditor/editorUI/modals/EditorInsertImageModal'
import { imageSearch } from '../../actions/imageSearch'
import { showNotification } from '../../actions/notifications'

const MAX_FILE_SIZE = 20000000

class EditorInsertImageModalContainer extends React.Component {
  constructor() {
    super()
    this.handleSelectImage = this.handleSelectImage.bind(this)
    this.handleNewImageSearch = this.handleNewImageSearch.bind(this)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handleSelectFile = this.handleSelectFile.bind(this)
    this.handleImageSearchBarPaste = this.handleImageSearchBarPaste.bind(this)
    this.clearSearch = this.clearSearch.bind(this)

    this.state = ({
      submitLoading: false,
      images: [],
      searchTerm: '',
      isSearching: false,
      noResults: false,
      searchResultCount: null,
      count: 50,
      offset: 0,
    })
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    e.stopPropagation()
  }

  handleImageSearchBarPaste(event) {
    const {
      insertPos, isQuestion, questionPos, questionNode,
    } = this.props

    const { clipboardData } = event
    const html = event.clipboardData.getData('text/html')
    let src = null
    const thumbnail = null
    let file = null
    const { files } = clipboardData
    const pastedFile = files[0]
    if (pastedFile) {
      file = pastedFile
    } else if (html) {
      const regExp = '<img[^>]* src="([^"]*)"[^>]*>'
      const match = html.match(regExp)
      // check if has an image in it
      if (match && match[1]) { // handle google docs images
        src = match[1]
      }
    }

    if (file || src) {
      if (file && file.size > MAX_FILE_SIZE) {
        this.props.closeInsertImageModal()
        this.props.showNotification('The maximum file size you can upload is 20mb.', 'Sadly your image file is too large. ', 'destroy')
      } else {
        this.props.closeInsertImageModal()
        const title = ''
        const attribution = ''
        const isCopyPaste = true
        const isSearchBarPaste = true
        const isImageSearch = false
        if (isQuestion) {
          this.props.addQuestionImage(src, thumbnail, title, attribution, insertPos, questionPos, questionNode, file, isImageSearch, isCopyPaste, isSearchBarPaste)
        } else {
          this.props.addChoiceImage(src, thumbnail, title, attribution, insertPos, file, isImageSearch, isCopyPaste, isSearchBarPaste)
        }
      }
    }
  }

  clearSearch() {
    this.setState({
      isSearching: false, images: [], searchTerm: '', offset: 0, searchResultCount: null, noResults: false,
    })
  }

  handleNewImageSearch(searchTerm) {
    if (searchTerm) {
      if (window.analytics) {
        window.analytics.track('Image search', {
          searchTerm,
        })
      }

      this.setState({ isSearching: true, noResults: false, images: [] })
      this.props.imageSearch(searchTerm, this.state.count, 0).then((response) => {
        // this.setState({isSearching:false})
        if (response.value.length === 0) {
          this.setState({ noResults: true })
        }
        const images = []
        response.value.forEach((value) => {
          images.push(this.parseImageObject(value))
        })
        this.setState({
          isSearching: false, images, searchTerm, offset: response.nextOffset, searchResultCount: response.totalEstimatedMatches,
        })
      })
        .catch(() => {
          this.setState({ images: [] })
        })
    }
  }

  handleLoadMore() {
    const { searchTerm, count, offset } = this.state
    this.setState({ isSearching: true })
    this.props.imageSearch(searchTerm, count, offset).then((response) => {
      const { images } = this.state
      response.value.forEach((value) => {
        images.push(this.parseImageObject(value))
      })
      this.setState({
        isSearching: false, images, offset: response.nextOffset, searchResultCount: response.totalEstimatedMatches,
      })
    })
    if (window.analytics) {
      window.analytics.track('Image search load more', {
        searchTerm,
      })
    }
  }

  parseImageObject(value) {
    return {
      src: value.contentUrl,
      title: value.name,
      attribution: value.contentUrl,
      thumbnail: value.thumbnailUrl,
      thumbnailWidth: value.thumbnail.width,
      thumbnailHeight: value.thumbnail.height,
      caption: '',
      accentColor: value.accentColor,
    }
  }

  handleSelectImage(imageIndex) {
    if (!this.state.submitLoading) {
      this.setState({ submitLoading: true })
      const img = this.state.images[imageIndex]
      const thumbnail = {
        src: img.thumbnail,
        w: img.thumbnailWidth,
        h: img.thumbnailHeight,
      }
      const { src } = img
      const { title } = img
      const { attribution } = img
      const file = null
      const isCopyPaste = false
      const isSearchBarPaste = false
      const isImageSearch = true

      this.props.closeInsertImageModal()
      const {
        insertPos, isQuestion, questionPos, questionNode,
      } = this.props
      if (isQuestion) {
        this.props.addQuestionImage(src, thumbnail, title, attribution, insertPos, questionPos, questionNode, file, isImageSearch, isCopyPaste, isSearchBarPaste)
      } else {
        this.props.addChoiceImage(src, thumbnail, title, attribution, insertPos, file, isImageSearch, isCopyPaste, isSearchBarPaste)
      }
    }
  }

  handleSelectFile(e) {
    const fileList = e.target.files
    const file = fileList[0]
    if (file.size > MAX_FILE_SIZE) {
      // this.props.closeInsertImageModal()
      this.props.showNotification('The maximum file size you can upload is 20mb.', 'Sadly your image file is too large. ', 'destroy')
    } else {
      setTimeout(() => { // Start the timer
        this.props.closeInsertImageModal()
      }, 200)

      const src = null
      const thumbnail = null
      const {
        insertPos, isQuestion, questionPos, questionNode,
      } = this.props
      let fileName = file.name
      const extensions = ['.png', '.jpg', '.jpeg', '.tif', '.tiff', '.webp', '.gif']
      extensions.forEach((extension) => {
        fileName = fileName.replace(extension, '')
      })

      const title = fileName
      const attribution = ''
      // const isUpload=true
      if (isQuestion) {
        this.props.addQuestionImage(src, thumbnail, title, attribution, insertPos, questionPos, questionNode, file)
      } else {
        this.props.addChoiceImage(src, thumbnail, title, attribution, insertPos, file)
      }
    }
  }

  render() {
    return (
      <div className='editor-insertImageModalContainer'>
        <EditorInsertImageModal
          noResults={this.state.noResults}
          isSearching={this.state.isSearching}
          handleNewImageSearch={this.handleNewImageSearch}
          loadMore={this.handleLoadMore}
          searchTerm={this.state.searchTerm}
          onSelectImage={this.handleSelectImage}
          images={this.state.images}
          handleSelectFile={this.handleSelectFile}
          handleImageSearchBarPaste={this.handleImageSearchBarPaste}
          closeInsertImageModal={this.props.closeInsertImageModal}
          searchResultCount={this.state.searchResultCount}
          clearSearch={this.clearSearch}
        />
        <div onClick={this.props.closeInsertImageModal} className='editor-insertImageModalContainerBG' />
      </div>
    )
  }
}

function mapStateToProps() {
  return {
  }
}

export default connect(
  mapStateToProps,
  {
    addQuestionImage,
    addChoiceImage,
    imageSearch,
    showNotification,
    // uploadNewImageToCloudinary
  },
)(EditorInsertImageModalContainer)
