export function clearUserAndSendToLogin(dispatch) {
  clearCurrentUser(dispatch)
  window.location = '/login'
}

function clearCurrentUser(dispatch) {
  // TODO: check and list all user-related localStorage items here
  localStorage.removeItem('ls.session')
  localStorage.removeItem('plickers-tab-sync')
  localStorage.removeItem('service.product')
  localStorage.removeItem('meta.setGroup')
  localStorage.removeItem('lastViewedDiscover')
  dispatch({ type: 'LOGOUT' })
  // remove user from segment tracking
  if (window.analytics) window.analytics.reset()
}
