import React, { Component } from 'react'
import moment from 'moment'
import Button from '../uiKit/Button'
import ComplexIcon from '../misc/ComplexIcons'

function formatTimestamp(timestamp) { // TODO move to utils
  return moment(timestamp).format('MMMM DD YYYY')
}

class DowngradeModalDowngradeFeature extends React.Component {
  render() {
    return (
      <div className='downgradeModal--downgrade-feature'>
        <div className='downgradeModal--downgrade-feature-left'>
          <ComplexIcon name={`proFeatureSymbol-${this.props.iconName}`} />
        </div>
        <div className='downgradeModal--downgrade-feature-right'>
          <div className='downgradeModal--downgrade-feature-right-label'>
            {this.props.label}
          </div>
          {this.props.subLabel && (
          <div className='downgradeModal--downgrade-feature-right-subLabel'>
            {this.props.subLabel}
          </div>
          )}

        </div>

      </div>
    )
  }
}

class DowngradeModal extends Component {
  constructor(props) {
    super(props)
    this.handleDowngrade = this.handleDowngrade.bind(this)
    this.state = {
      submitLoading: false,
      errorMessage: null,
    }
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Downgrade Modal')
    }
  }

  handleDowngrade() {
    this.setState({ submitLoading: true })
    if (window.analytics) window.analytics.track('Downgrade plan', {})
    const { service } = this.props
    this.props.cancelService().then((response) => {
      if (response && !response.message) {
        let status
        if (service) {
          status = service.status // status is trial, active or cancelled
        }
        this.props.showFeedbackModal(status)
      } else {
        this.setState({ submitLoading: false, errorMessage: 'Unable to downgrade, please contact support if you are having problems.' })
      }
    })
  }

  render() {
    const { service } = this.props
    const { errorMessage, submitLoading } = this.state
    const downgradeDate = formatTimestamp(service.paidUntil)
    let status
    if (service) {
      status = service.status // status is trial, active or cancelled
    }
    let downgradeButtonText = ''
    if (status === 'trial') {
      downgradeButtonText = 'I want to cancel my free trial now'
    } else if (status === 'active') {
      downgradeButtonText = 'I want to cancel immediately'
    }

    let downgradeCancelButtonText = ''
    if (status === 'trial') {
      downgradeCancelButtonText = 'I want to continue my free trial'
    } else if (status === 'active') {
      downgradeCancelButtonText = 'I want to stay Pro'
    }

    return (
      <div className='downgradeModal downgradeModal--downgrade'>
        {status === 'active' && (
        <div className='downgradeModal-h1'>
          Cancel Pro Subscription
        </div>
        )}

        {status === 'active' && (
        <div className='downgradeModal-h2'>
          Thank you so much for supporting us. We mean it.
        </div>
        )}

        {status === 'trial' && (
        <div className='downgradeModal-h1'>
          Cancel Trial
        </div>
        )}

        {status === 'trial' && (
        <div className='downgradeModal-h2'>
          We’ll be sorry to see you go.
        </div>
        )}

        <div className='downgradeModal-h3'>
          Before you go, here's a quick reminder of what you’ll be missing out on&nbsp;
          {status === 'trial' && <React.Fragment>if you cancel your trial today:</React.Fragment>}
          {status === 'active' && <React.Fragment>if you cancel your Pro subscription today:</React.Fragment>}
        </div>

        <DowngradeModalDowngradeFeature
          label='Shared Packs'
          subLabel={(
            <React.Fragment>
              <b>You will lose access to any Shared Packs when you downgrade.</b>
              <br />
              {' '}
              You can regain access to them if you resubscribe to Plickers Pro.
            </React.Fragment>
)}
          iconName='sharedPack'
        />

        <DowngradeModalDowngradeFeature
          label='No Set Limits'
          subLabel='Sets are limited to 5 questions on free accounts'
          iconName='limit'
        />

        <DowngradeModalDowngradeFeature
          label='Unlimited Scoresheet'
          iconName='scoresheet'
        />

        <DowngradeModalDowngradeFeature
          label='Instant Images'
          iconName='images'
        />

        <button className='btn downgradeModal-primaryButton' disabled={submitLoading} onClick={this.handleDowngrade}>

          {!submitLoading && (
          <React.Fragment>
            <div className='downgradeModal-primaryButton-headline'>
              {downgradeButtonText}
            </div>
            <div className='downgradeModal-primaryButton-subline'>
              You will not be charged
              {status === 'active' && <React.Fragment>&nbsp; again</React.Fragment>}
              .
            </div>
            <div className='downgradeModal-primaryButton-subline'>
              You will keep your Pro functionality until
              {' '}
              {downgradeDate}
              .
            </div>
          </React.Fragment>
          )}

          {submitLoading && (
          <div className='downgradeModal-primaryButton-submitLoading'>
            <div className='downgradeModal-primaryButton-submitLoading-spinnerContainer'>
              <div className='downgradeModal-primaryButton-submitLoading-spinner' />
            </div>
          </div>
          )}

        </button>

        <Button name='downgradeModalSecondary' color='white' size='large' label={`${downgradeCancelButtonText}`} onClickFunction={this.props.hideModal} />

        {errorMessage && <div>{errorMessage}</div> }

      </div>
    )
  }
}

export default DowngradeModal
