//use poll.filteredPollPolls (filtered polls is excluding excluded pollPolls)
export function buildReportPollsByStudent(historyPolls,students,guests,showCorrect,showIncorrect,showMissing,showSurveys){
	let reportPollsByStudent={}
	function shouldIncludePoll(response,poll){
		if(!showSurveys && poll.correctPercent===-1){
			return false
		}else if(!response){
			if(showMissing){				
				return true
			}else{			
				return false
			}
		}else if(response.isCorrect===true){
			if(showCorrect===true){
				return true
			}else{
				return false
			}
		}else if(response.isCorrect===false){
			if(showIncorrect===true){
				return true
			}else{
				return false
			}
		}
		else return false
	}

	students.forEach((student)=>{
		let studentPolls=[]
		let studentPollCount=0	
		historyPolls.map((poll)=>{
			if(poll.setObject){
				//work out which of pollPolls to include (then get rid of set if has no poll polls)
				let studentPollPolls={}
				Object.keys(poll.filteredPolls).forEach((key)=>{
					const pollPoll=poll.filteredPolls[key]
					const response=pollPoll.responsesByStudent[student.id]
					const includePoll=shouldIncludePoll(response,pollPoll)
					if(includePoll){
						studentPollPolls[key]=pollPoll
						studentPollCount+=1
					}
				})	
				if(Object.keys(studentPollPolls).length !==0){
					const studentSetPoll=Object.assign({},poll)
					studentSetPoll.filteredPolls=Object.assign({},studentPollPolls)
					studentPolls.push(studentSetPoll)
				}
			}else{
				const response=poll.responsesByStudent[student.id]
				const includePoll=shouldIncludePoll(response,poll)
				if(includePoll){
					studentPolls.push(poll)
					studentPollCount+=1
				}
			}
			return null
		})
		reportPollsByStudent[student.id]={
			isArchived:student.archived,
			polls:studentPolls,
			questionCount:studentPollCount,
		}
	})

	guests.forEach((guest)=>{
		let guestPolls=[]
		let guestPollCount=0
		historyPolls.map((poll)=>{
			if(poll.setObject){
				//work out which of pollPolls to include (then get rid of set if has no poll polls)
				let guestPollPolls={}
				Object.keys(poll.filteredPolls).forEach((key)=>{
					const pollPoll=poll.filteredPolls[key]
					const response=pollPoll.responsesByGuest[guest]
					const includePoll=shouldIncludePoll(response,pollPoll)
					if(includePoll){
						guestPollPolls[key]=pollPoll
						guestPollCount+=1
					}
				})	
				if(Object.keys(guestPollPolls).length !==0){
					const guestSetPoll=Object.assign({},poll)
					guestSetPoll.filteredPolls=Object.assign({},guestPollPolls)
					guestPolls.push(guestSetPoll)
				}
			}else{
				const response=poll.responsesByGuest[guest]
				const includePoll=shouldIncludePoll(response,poll)
				if(includePoll){
					guestPolls.push(poll)
					guestPollCount+=1
				}
			}
			return null
		})
		
		reportPollsByStudent[guest]={
			isGuest:true,
			polls:guestPolls,
			questionCount:guestPollCount,
		}
	})
	return reportPollsByStudent
}

