export function getTotalPollCount(polls) {
	let totalPollCount=0
	polls.map((poll)=>{
		if(poll.setObject){ //is a setPoll
			Object.keys(poll.filteredPolls).map((key,index)=>{
				totalPollCount+=1
				return null
			})
		}else{
			totalPollCount+=1
		}
		return null
	})						
	return totalPollCount
}

