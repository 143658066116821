import * as Sentry from '@sentry/browser'
import api from '../api'
import { fetchMeta } from './meta'
import { fetchService } from './service'
import store from '../store'
import { ensureDataOwners } from './dataOwner'

export function fetchUser(currentUserId) {
  if (!currentUserId) { // TEMP for debugging
    Sentry.withScope((scope) => {
      scope.setExtra('userId', currentUserId)
      Sentry.captureException('request fetch user with no userId')
    })
  }

  return (dispatch) => api.fetch(`/users/${currentUserId}`)
    .then((response) => {
      dispatch({ type: 'FETCH_USER_SUCCESS', response })
      if (window.analytics) {
        window.analytics.identify(response.id, {
          userCreated: response.userCreated,
          email: response.email,
          id: response.id,
        })
      }
      dispatch(ensureDataOwners(response))
    })
    .catch(() => {})
}

export function fetchUserByUsername(username) {
  const state = store.getState()
  // check if have already requested to prevent duplicate requests
  if (state.userProfiles[username]) {
    return ({ type: 'WAIT_TO_REFRESH' })
  }

  return (dispatch) => {
    dispatch({ type: 'FETCH_USER_PROFILE_REQUEST', username })
    return api.fetch(`/users?username=${username}`)
      .then((response) => {
        dispatch({ type: 'FETCH_USER_PROFILE_SUCCESS', response })
        return response
      })
      .catch(() => {}) // TODO error handling
  }
}

export function refetchUserByUsername(username) { // fetch even if has been requested before
  return (dispatch) => api.fetch(`/users?username=${username}`)
    .then((response) => {
      dispatch({ type: 'FETCH_USER_PROFILE_SUCCESS', response })
      return response
    })
    .catch(() => {})
}

export function fetchUserSignInMethods(currentUserId) {
  return (dispatch) => api.fetch(`/users/${currentUserId}/signin-methods`)
    .then((response) => {
      dispatch({ type: 'FETCH_USER_SIGNIN_METHODS_SUCCESS', response })
      return response
    })
    .catch(() => {})
}

export function updateExternalSignin(currentUserId, data, logOut) {
  return (dispatch) => api.put(`/users/${currentUserId}/external-signin/update?logOut=${logOut}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_GOOGLE_SIGNIN_SUCCESS', response })
      dispatch(fetchUser(currentUserId))
      dispatch(fetchMeta())
      dispatch(fetchService())
      return response
    })
}

export function addPassword(currentUserId, data) {
  return (dispatch) => api.put(`/users/${currentUserId}/add-password`, data)
    .then((response) => {
      dispatch({ type: 'ADD_PASSWORD_SUCCESS', response })
      return response
    })
}

export function addExternalSignin(currentUserId, data, type) {
  return (dispatch) => api.put(`/users/${currentUserId}/external-signin/add`, data)
    .then((response) => {
      if (type === 'apple') {
        dispatch({ type: 'ADD_APPLE_SIGNIN_SUCCESS', response })
      } else {
        dispatch({ type: 'ADD_GOOGLE_SIGNIN_SUCCESS', response })
      }
      dispatch(fetchMeta())
      return response
    })
}

export function removeExternalSignin(currentUserId, data, logOut) {
  return (dispatch) => api.put(`/users/${currentUserId}/external-signin/remove?logOut=${logOut}`, data)
    .then((response) => {
      dispatch({ type: 'REMOVE_EXTERNAL_SIGNIN_SUCCESS', response })
      dispatch(fetchMeta())
      dispatch(fetchService())
      return response
    })
}

export function resendEmailConfirmation(currentUserId) {
  return () => api.put(`/users/${currentUserId}/resend-email-confirmation`)
    .then((response) => response)
}

export function emailAppLinks(currentUserId) {
  return () => api.put(`/users/${currentUserId}/email-app-links`)
    .then((response) => response)
}

export function updateUser(data, userId) {
  return (dispatch) => api.put(`/users/${userId}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_USER_SUCCESS', response, syncAction: true })
      if (response.username) {
        dispatch(refetchUserByUsername(response.username))
      }
      return response
    })
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setExtra('request', data)
        Sentry.captureException(new Error('Update user error'))
      })
      throw error
    })
}

export function updateUserEmail(data, userId) {
  return (dispatch) => api.put(`/users/${userId}/update-email`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_USER_SUCCESS', response, syncAction: true })
      dispatch(fetchMeta())
      return response
    })
}

export function updateUserPayoutDestination(data, userId) {
  return (dispatch) => api.put(`/users/${userId}/payoutDestination`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_USER_SUCCESS', response, syncAction: true })
      dispatch(fetchMeta())
      return response
    })
}

export function updateUserPassword(data, userId, logOut) {
  return (dispatch) => api.put(`/users/${userId}/update-password?logOut=${logOut}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_USER_SUCCESS', response, syncAction: true })
      return response
    })
}

export function addDataOwner(dataOwnerId) {
  return (dispatch) => api.put('/users/dataOwners/add', { dataOwner: dataOwnerId })
    .then((response) => {
      dispatch({ type: 'UPDATE_USER_SUCCESS', response, syncAction: true })
      return response
    })
}

export function forgotPassword(emailAddress, recaptcha) {
  return () => api.put(
    `/users/forgot-password?email=${encodeURIComponent(emailAddress)}`,
    { recaptchaResponse: recaptcha },
  )
    .then((response) => response)
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        Sentry.withScope((scope) => {
          scope.setExtra('email', emailAddress)
          scope.setExtra('recaptcha', recaptcha)
          Sentry.captureException(new Error('Forgot Password 400 Error'))
        })
      }
      throw error
    })
}

export function resetPassword(userId, data) {
  return () => api.put(`/users/${userId}/reset-password`, data)
    .then((response) => response)
}

export function confirmEmailWithIdentity(userId, identity) {
  return () => api.put(`/users/${userId}/confirm-email-with-identity`, { identity })
    .then((response) => response)
}

export function confirmEmail(userId, token) {
  return () => api.put(`/users/${userId}/confirm-email`, { token })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function sendDeleteAccountLink(userId, payload) {
  return () => api.post('/users/delete-account-link', payload)
    .then((response) => response)
}

export function getAccountData(userId, token) {
  return () => api.fetch(`/users/${userId}/account-data-summary?token=${token}`)
    .then((response) => response)
}

export function deleteAccount(userId, token) {
  return () => api.post(`/users/${userId}/delete-account`, { token })
    .then((response) => response)
}

// check if username is available when joining creator programme
export function testUsername(username) {
  return () => api.fetch(`/users?username=${username}`)
    .then((response) => response)
}
